import { CustomerMaterialSheet, CustomerMaterialTube, CustomerMaterialType, TubeProfileClassification } from "@oshcut/oshlib";
import { joinWithMaterialTypes } from "joinMaterials";
import { useMemo } from "react";
import { CatalogFilterType, CatalogStateType } from "./Catalog";

export const roundShapes = ['Round', 'Round Tube', 'Pipe']

type CustomSheetFilter = (material: CustomerMaterialType & CustomerMaterialSheet) => boolean
type CustomTubeFilter = (material: CustomerMaterialType & CustomerMaterialTube) => boolean

/**
 * Returns material sheets, materials, supertypes, and thicknesses that match the current filter.
 * @param state The catalog app state
 * @param customFilter A custom filter function that can be used to further filter the results
 * @returns An object containing the matching sheets, materials, supertypes, and thicknesses.
 */
export function useMaterialSheetFilter(state: CatalogStateType, customFilter?: CustomSheetFilter | null) {

  let customFilterWithDefault = customFilter ?? (() => true)

  /** The material sheets that match the current filter */
  const matchingSheets = useMemo(() => {
    if (!state.materialSheets || !state.materialTypes) return []
    return joinWithMaterialTypes(state.materialSheets, state.materialTypes)
      .filter(sheet => matchSheet(sheet, state.filter) && customFilterWithDefault(sheet))
  }, [state.materialSheets, state.materialTypes, state.filter, customFilter])

  const matchingMaterials = useMemo(() => {
    if (!state.materialTypes) return []
    let materialDescriptions = [...new Set(matchingSheets.map(sheet => sheet.description))]
    return state.materialTypes.filter(type => materialDescriptions.includes(type.description))
  }, [state.materialTypes, matchingSheets, state.filter.materialDescriptions])

  const matchingSupertypes = useMemo(() => {
    let supertypes = [...new Set(matchingMaterials.map(material => material.supertype))]
    return supertypes
  }, [matchingSheets, matchingMaterials, state.filter.supertypes])

  const matchingThicknesses = useMemo(() => {
    let thicknesses = [...new Set(matchingSheets.map(material => material.thickness))]
    return thicknesses.sort()
  }, [matchingSheets, state.filter.thicknesses])

  return {
    matchingSheets,
    matchingMaterials,
    matchingSupertypes,
    matchingThicknesses,
  }

}


/**
 * Returns material sheets, materials, supertypes, and thicknesses that should be displayed in the sidebar. In addition
 * to the sheets matched by useMaterialSheetFilter, this function will also include the filter items that are explicitly
 * specified by the current filter, even if those items would have been filtered out by other filters. In addition, for
 * each filter box, the list of items will _not_ be filtered by the filtering variable for that filter box. This is used
 * by the sidebar so that clicking on a filter item does not hide the remaining items in that list.
 * @param state The catalog app state
 * @param customFilter A custom filter function that can be used to further filter the results
 * @returns An object containing the matching sheets, materials, supertypes, and thicknesses.
 */
export function useMaterialSheetFilterSidebar(state: CatalogStateType, customFilter?: CustomSheetFilter | null) {

  let customFilterWithDefault = customFilter ?? (() => true)

  let allMaterials = state.materialSheets && state.materialTypes && joinWithMaterialTypes(state.materialSheets, state.materialTypes)

  /** The matching materials. Only other filters are applied. */
  const matchingMaterials = useMemo(() => {
    if (!allMaterials || !state.materialTypes) return []

    // Get matching sheets, assuming materialDescription is not set
    let matchingSheets = allMaterials.filter(sheet => matchSheet(sheet, { ...state.filter, materialDescriptions: undefined }) && customFilterWithDefault(sheet))

    // Add the explicitly specified materialDescription to the list of matching sheets
    let materialDescriptions = [...new Set(matchingSheets.map(sheet => sheet.description).concat(state.filter.materialDescriptions ?? []))]
    return state.materialTypes.filter(type => materialDescriptions.includes(type.description))
  }, [state.materialTypes, allMaterials, state.filter.materialDescriptions])

  /** The matching supertypes. Only other filters are applied. */
  const matchingSupertypes = useMemo(() => {
    if (!allMaterials || !state.materialTypes) return []

    // Get matching sheets, assuming supertype is not set
    let matchingSheets = allMaterials.filter(sheet => matchSheet(sheet, { ...state.filter, supertypes: undefined }) && customFilterWithDefault(sheet))

    // Add the explicitly specified supertype to the list of matching sheets
    let supertypes = [...new Set(matchingSheets.map(material => material.supertype).concat(state.filter.supertypes ?? []))]
    return supertypes
  }, [state.materialTypes, allMaterials, state.filter.supertypes])

  /** The matching thicknesses. Only other filters are applied. */
  const matchingThicknesses = useMemo(() => {
    if (!allMaterials || !state.materialTypes) return []

    // Get matching sheets, assuming thickness is not set
    let matchingSheets = allMaterials.filter(sheet => matchSheet(sheet, { ...state.filter, thicknesses: undefined }) && customFilterWithDefault(sheet))

    // Add the explicitly specified thickness to the list of matching sheets
    let thicknesses = [...new Set(matchingSheets.map(material => material.thickness).concat(state.filter.thicknesses ?? []))]
    return thicknesses.sort()
  }, [state.materialTypes, allMaterials, state.filter.thicknesses])

  const allMaterialTypes = useMemo(() => {
    if (!state.materialTypes) return []
    return state.materialTypes
  }, [state.materialTypes])

  const allSupertypes = useMemo(() => {
    if (!allMaterials) return []
    let supertypes = [...new Set(allMaterials.map(material => material.supertype))]
    return supertypes
  }, [allMaterials])

  const allThicknesses = useMemo(() => {
    if (!allMaterials) return []
    let thicknesses = [...new Set(allMaterials.map(material => material.thickness))]
    return thicknesses.sort()
  }, [allMaterials])

  return {
    allMaterialTypes,
    allSupertypes,
    allThicknesses,
    matchingMaterials,
    matchingSupertypes,
    matchingThicknesses,
  }
}

/**
 * Returns material tubes, materials, supertypes, shapes, and tube names that match the current filter.
 * @param state The catalog app state
 * @param customFilter A custom filter function that can be used to further filter the results
 * @returns An object containing the matching tubes, materials, supertypes, shapes, and tube names.
 */
export function useMaterialTubeFilter(state: CatalogStateType, customFilter?: CustomTubeFilter | null) {

  let customFilterWithDefault = customFilter ?? (() => true)

  /** The material tubes that match the current filter */
  const matchingTubes = useMemo(() => {
    if (!state.materialTubes || !state.materialTypes) return []
    return joinWithMaterialTypes(state.materialTubes, state.materialTypes)
      .filter(tube => matchTube(tube, state.filter) && customFilterWithDefault(tube))
  }, [state.materialTubes, state.materialTypes, state.filter, customFilter])

  const matchingMaterials = useMemo(() => {
    if (!state.materialTypes) return []
    let materialDescriptions = [...new Set(matchingTubes.map(tube => tube.description))]

    return state.materialTypes.filter(type => materialDescriptions.includes(type.description))
  }, [state.materialTypes, matchingTubes, state.filter.materialDescriptions])

  const matchingSupertypes = useMemo(() => {
    let supertypes = [...new Set(matchingMaterials.map(material => material.supertype))]
    return supertypes
  }, [matchingTubes, matchingMaterials, state.filter.supertypes])

  const matchingShapes = useMemo(() => {
    let shapes = [...new Set(matchingTubes.map(tube => tube.shape))]
    return shapes
  }, [matchingTubes, state.filter.shapes])

  const matchingWidths = useMemo(() => {
    let widths = [...new Set(matchingTubes.map(tube => tube.profile_size_x))]
    return widths.sort()
  }, [matchingTubes, state.filter.widths])

  const matchingHeights = useMemo(() => {
    let heights = [...new Set(matchingTubes.map(tube => tube.profile_size_y))]
    return heights.sort()
  }, [matchingTubes, state.filter.heights])

  const matchingWallThicknesses = useMemo(() => {
    let wallThicknesses = [...new Set(matchingTubes.map(tube => tube.wall_thickness))]
    return wallThicknesses.sort()
  }, [matchingTubes, state.filter.wallThicknesses])

  const matchingPipeSizes = useMemo(() => {
    let pipeSizes = [...new Set(matchingTubes.sort(pipeSorter).map(tube => tube.nominal_pipe_size))]
    return pipeSizes
  }, [matchingTubes, state.filter.pipeSizes])

  const matchingPipeSchedules = useMemo(() => {
    let pipeSchedules = [...new Set(matchingTubes.sort(pipeSorter).map(tube => tube.pipe_schedule))]
    return pipeSchedules
  }, [matchingTubes, state.filter.pipeSchedules])

  return {
    matchingTubes,
    matchingMaterials,
    matchingSupertypes,
    matchingShapes,
    matchingWidths,
    matchingHeights,
    matchingWallThicknesses,
    matchingPipeSizes,
    matchingPipeSchedules,
  }
}

/**
 * Returns material tubes, materials, supertypes, shapes, and tube names that should be displayed in the sidebar. In
 * addition to the tubes matched by useMaterialTubeFilter, this function will also include the filter items that are
 * explicitly specified by the current filter, even if those items would have been filtered out by other filters. In
 * addition, for each filter box, the list of items will _not_ be filtered by the filtering variable for that filter
 * box. This is used by the sidebar so that clicking on a filter item does not hide the remaining items in that list.
 * @param state The catalog app state
 * @param customFilter A custom filter function that can be used to further filter the results
 * @returns An object containing the matching tubes, materials, supertypes, shapes, and tube names.
 */
export function useMaterialTubeFilterSidebar(state: CatalogStateType, customFilter?: CustomTubeFilter | null) {

  let customFilterWithDefault = customFilter ?? (() => true)

  let allTubes = state.materialTubes && state.materialTypes && joinWithMaterialTypes(state.materialTubes, state.materialTypes)

  /** The matching materials. Only other filters are applied. */
  const matchingMaterials = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming materialDescription is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, materialDescriptions: undefined }) && customFilterWithDefault(tube))

    // Add the explicitly specified materialDescription to the list of matching tubes
    let materialDescriptions = [...new Set(matchingTubes.map(tube => tube.description).concat(state.filter.materialDescriptions ?? []))]
    return state.materialTypes.filter(type => materialDescriptions.includes(type.description))
  }, [state.materialTypes, allTubes, state.filter.materialDescriptions])

  /** The matching supertypes. Only other filters are applied. */
  const matchingSupertypes = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming supertype is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, supertypes: undefined }) && customFilterWithDefault(tube))

    // Add the explicitly specified supertype to the list of matching tubes
    let supertypes = [...new Set(matchingTubes.map(material => material.supertype).concat(state.filter.supertypes ?? []))]
    return supertypes
  }, [state.materialTypes, allTubes, state.filter.supertypes])

  /** The matching shapes. Only other filters are applied. */
  const matchingShapes = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming shape is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, shapes: undefined }) && customFilterWithDefault(tube))

    // Add the explicitly specified shape to the list of matching tubes
    let shapes = [...new Set(matchingTubes.map(tube => tube.shape).concat(state.filter.shapes ?? []))]
    return shapes
  }, [state.materialTypes, allTubes, state.filter.shapes])

  /** The matching widths. Only other filters are applied. */
  const matchingWidths = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming width is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, widths: undefined }) && customFilterWithDefault(tube))

    // Add the explicitly specified width to the list of matching tubes
    let widths = [...new Set(matchingTubes.map(tube => tube.profile_size_x).concat(state.filter.widths ?? []))]
    return widths.sort()
  }, [state.materialTypes, allTubes, state.filter.widths])

  /** The matching heights. Only other filters are applied. */
  const matchingHeights = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming height is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, heights: undefined }) && customFilterWithDefault(tube))

    // Add the explicitly specified height to the list of matching tubes
    let heights = [...new Set(matchingTubes.map(tube => tube.profile_size_y).concat(state.filter.heights ?? []))]
    return heights.sort()
  }, [state.materialTypes, allTubes, state.filter.heights])

  /** The matching wall thicknesses. Only other filters are applied. */
  const matchingWallThicknesses = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming wallThickness is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, wallThicknesses: undefined }) && customFilterWithDefault(tube))

    // Add the explicitly specified wallThickness to the list of matching tubes
    let wallThicknesses = [...new Set(matchingTubes.map(tube => tube.wall_thickness).concat(state.filter.wallThicknesses ?? []))]
    return wallThicknesses.sort()
  }, [state.materialTypes, allTubes, state.filter.wallThicknesses])

  /** The matching pipe sizes. Only other filters are applied. */
  const matchingPipeSizes = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming pipeSize is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, pipeSizes: undefined }) && customFilterWithDefault(tube))
    matchingTubes.sort(pipeSorter)
    // Add the explicitly specified pipeSize to the list of matching tubes
    let pipeSizes = [...new Set(matchingTubes.map(tube => tube.nominal_pipe_size).concat(state.filter.pipeSizes ?? []))]
      .filter((pipeSize): pipeSize is string => pipeSize != null) // Remove undefined (non-pipe) sizes
    return pipeSizes
  }, [state.materialTypes, allTubes, state.filter.pipeSizes])

  /** The matching pipe sizes. Only other filters are applied. */
  const matchingPipeSchedules = useMemo(() => {
    if (!allTubes || !state.materialTypes) return []

    // Get matching tubes, assuming pipeSize is not set
    let matchingTubes = allTubes.filter(tube => matchTube(tube, { ...state.filter, pipeSizes: undefined }) && customFilterWithDefault(tube))
    matchingTubes.sort(pipeSorter)
    // Add the explicitly specified pipeSize to the list of matching tubes
    let pipeSizes = [...new Set(matchingTubes.map(tube => tube.pipe_schedule).concat(state.filter.pipeSizes ?? []))]
      .filter((pipeSize): pipeSize is string => pipeSize != null) // Remove undefined (non-pipe) sizes
    return pipeSizes
  }, [state.materialTypes, allTubes, state.filter.pipeSizes])


  const allMaterialTypes = useMemo(() => {
    if (!state.materialTypes) return []
    return state.materialTypes
  }, [state.materialTypes])

  const allSupertypes = useMemo(() => {
    if (!allTubes) return []
    let supertypes = [...new Set(allTubes.map(material => material.supertype))]
    return supertypes
  }, [allTubes])

  const allShapes = useMemo(() => {
    if (!allTubes) return []
    let shapes = [...new Set(allTubes.map(tube => tube.shape))]
    return shapes
  }, [allTubes])

  const allWidths = useMemo(() => {
    if (!allTubes) return []
    let widths = [...new Set(allTubes.map(tube => tube.profile_size_x))]
    return widths.sort()
  }, [allTubes])

  const allHeights = useMemo(() => {
    if (!allTubes) return []
    let heights = [...new Set(allTubes.map(tube => tube.profile_size_y))]
    return heights.sort()
  }, [allTubes])

  const allWallThicknesses = useMemo(() => {
    if (!allTubes) return []
    let wallThicknesses = [...new Set(allTubes.map(tube => tube.wall_thickness))]
    return wallThicknesses.sort()
  }, [allTubes])

  const allPipeSizes = useMemo(() => {
    if (!allTubes) return []
    let pipeSizes = [...new Set(allTubes.filter(tube => tube.nominal_pipe_size).sort(pipeSorter).map(tube => tube.nominal_pipe_size))]
      .filter((pipeSize): pipeSize is string => pipeSize != null) // Remove undefined (non-pipe) sizes
    return pipeSizes
  }, [allTubes])

  const allPipeSchedules = useMemo(() => {
    if (!allTubes) return []
    let pipeSizes = [...new Set(allTubes.filter(tube => tube.pipe_schedule).sort(pipeSorter).map(tube => tube.pipe_schedule))]
      .filter((pipeSize): pipeSize is string => pipeSize != null) // Remove undefined (non-pipe) sizes
    return pipeSizes
  }, [allTubes])

  return {
    allMaterialTypes,
    allSupertypes,
    allShapes,
    allWidths,
    allHeights,
    allWallThicknesses,
    allPipeSizes,
    allPipeSchedules,
    matchingMaterials,
    matchingSupertypes,
    matchingShapes,
    matchingWidths,
    matchingHeights,
    matchingWallThicknesses,
    matchingPipeSizes,
    matchingPipeSchedules,
  }
}

/**
 * Returns true if the material of the specified thickness matches the specified filters.
 * @param sheet 
 * @param filter 
 */
export function matchSheet(sheet: CustomerMaterialSheet & CustomerMaterialType, filter: CatalogFilterType) {

  if (filter.bendable && !sheet.bending_allowed) return false

  if (filter.powderCoatable) {
    if (!sheet.powder_coating_allowed) return false
    if (sheet.powder_requires_finishing && !sheet.flat_finishing_allowed) return false
  }

  if (filter.materialDescriptions?.length && !filter.materialDescriptions.includes(sheet.description)) return false
  if (filter.supertypes?.length && !filter.supertypes.includes(sheet.supertype)) return false
  if (filter.thicknesses?.length && !filter.thicknesses.includes(sheet.thickness)) return false

  return true
}

/**
 * Returns true if the material tube matches the specified filters.
 * @param tube
 * @param filter
 */
export function matchTube(tube: CustomerMaterialTube & CustomerMaterialType, filter: CatalogFilterType) {

  if (filter.powderCoatable && !tube.powder_coating_allowed) return false
  if (filter.shapes?.length && !filter.shapes.includes(tube.shape)) return false
  if (filter.supertypes?.length && !filter.supertypes.includes(tube.supertype)) return false
  if (filter.materialDescriptions?.length && !filter.materialDescriptions.includes(tube.description)) return false
  if (filter.widths?.length && !filter.widths.includes(tube.profile_size_x)) return false
  if (filter.heights?.length && !filter.heights.includes(tube.profile_size_y)) return false
  if (filter.wallThicknesses?.length && !filter.wallThicknesses.includes(tube.wall_thickness)) return false
  if (filter.pipeSizes?.length && !filter.pipeSizes.includes(tube.nominal_pipe_size ?? '')) return false
  if (filter.pipeSchedules?.length && !filter.pipeSchedules.includes(tube.pipe_schedule ?? '')) return false

  if (filter.matchMaterialTubeId && filter.matchingTubeProfileClassifications) {
    if (!matchTubeClassifications(tube, filter.matchingTubeProfileClassifications)) return false
  }

  return true
}

/**
 * Returns true if the given material tube matches a nested array of tube profile classifications. The inner-most array
 * is a list of detected profile classifications of a single part, and only one of these must match the given material
 * tube to be considered a match for that part. The outer array represents the classifications of several parts, and all
 * of these must match the given material tube to be considered a match. So the inner array is a logical OR, and the
 * outer array is a logical AND. For example, the array `[['A', 'B'], ['C', 'D']]` represents the condition `((A || B)
 * && (C || D))`. If the array is empty, then the condition is considered to be true.
 */
export function matchTubeClassifications(tube: CustomerMaterialTube, classifications: TubeProfileClassification[][]) {
  // All of the outer arrays must match
  for (let classificationGroup of classifications) {
    let hasMatch = false
    // At least one of the inner arrays must match
    for (let classification of classificationGroup) {
      if (matchTubeClassification(tube, classification)) {
        hasMatch = true
        break
      }
    }
    if (!hasMatch) return false
  }
  return true
}

export function matchTubeClassification(tube: CustomerMaterialTube, classification: TubeProfileClassification) {

  // All dimensions must match within 0.02"

  // Wall thickness
  if (Math.abs(tube.wall_thickness - classification.wallThickness) > 0.02) return false

  let profileWidth = classification.outerRectangle.bbox.max.x - classification.outerRectangle.bbox.min.x
  let profileHeight = classification.outerRectangle.bbox.max.y - classification.outerRectangle.bbox.min.y

  if (classification.isCircular) {
    if (!roundShapes.includes(tube.shape)) return false
    if (Math.abs(tube.profile_size_x - profileWidth) > 0.02) return false
  } else {
    if (roundShapes.includes(tube.shape)) return false

    // Check size of outer rectangle. If no edges are open, then it must be the same size as the tube profile (90 deg rotations are fine).
    // If one edge is open, then it must match the size in the other direction, and be at least as big as the tube profile in the open direction.
    // If two edges are open, then it must be at least as big as the tube profile in both directions.

    let isWidthOpen = classification.isLeftEdgeOpen || classification.isRightEdgeOpen
    let isHeightOpen = classification.isTopEdgeOpen || classification.isBottomEdgeOpen

    // Try both orientations of the tube. One of the orientations must have both errors less than 0.02
    let errorWidth0 = 0
    let errorWidth90 = 0
    let errorHeight0 = 0
    let errorHeight90 = 0

    if (isWidthOpen) {
      // Profile width is open. Add the error only if the width of the profile is greater than the width of the tube.
      if (profileWidth > tube.profile_size_x) errorWidth0 += Math.abs(tube.profile_size_x - profileWidth)
      if (profileWidth > tube.profile_size_y) errorWidth90 += Math.abs(tube.profile_size_y - profileWidth)
    } else {
      errorWidth0 += Math.abs(tube.profile_size_x - profileWidth)
      errorWidth90 += Math.abs(tube.profile_size_y - profileWidth)
    }

    if (isHeightOpen) {
      // Profile height is open. Add the error only if the height of the profile is greater than the height of the tube.
      if (profileHeight > tube.profile_size_y) errorHeight0 += Math.abs(tube.profile_size_y - profileHeight)
      if (profileHeight > tube.profile_size_x) errorHeight90 += Math.abs(tube.profile_size_x - profileHeight)
    } else {
      errorHeight0 += Math.abs(tube.profile_size_y - profileHeight)
      errorHeight90 += Math.abs(tube.profile_size_x - profileHeight)
    }

    if ((errorWidth0 > 0.02 || errorHeight0 > 0.02) && (errorWidth90 > 0.02 || errorHeight90 > 0.02)) return false

    // Corner radius
    if (classification.outerRectangle.radius < (tube.min_corner_radius ?? 0) || classification.outerRectangle.radius > (tube.max_corner_radius ?? 0.02)) return false

  }

  return true
}

// This differs slightly from the arcify version.
export function getTubeProfileClassificationError(tube: CustomerMaterialTube, classification: TubeProfileClassification) {

  let error = 0

  // Wall thickness
  error += Math.abs(tube.wall_thickness - classification.wallThickness)

  let profileWidth = classification.outerRectangle.bbox.max.x - classification.outerRectangle.bbox.min.x
  let profileHeight = classification.outerRectangle.bbox.max.y - classification.outerRectangle.bbox.min.y

  if (classification.isCircular) {
    if (!roundShapes.includes(tube.shape)) error += 10
    error += Math.abs(tube.profile_size_x - profileWidth)
  } else {
    if (roundShapes.includes(tube.shape)) error += 10

    // Check size of outer rectangle. If no edges are open, then it must be the same size as the tube profile (90 deg rotations are fine).
    // If one edge is open, then it must match the size in the other direction, and be at least as big as the tube profile in the open direction.
    // If two edges are open, then it must be at least as big as the tube profile in both directions.

    let isWidthOpen = classification.isLeftEdgeOpen || classification.isRightEdgeOpen
    let isHeightOpen = classification.isTopEdgeOpen || classification.isBottomEdgeOpen

    // Try both orientations of the tube, and use the smallest error from those two trials
    let error1 = 0
    let error2 = 0

    if (isWidthOpen) {
      // Profile width is open. Add the full error only if the width of the profile is less than the width of the tube. Otherwise, add a small amount of the error.
      if (profileWidth > tube.profile_size_x) {
        error1 += Math.abs(tube.profile_size_x - profileWidth)
      } else {
        error1 += Math.abs(tube.profile_size_x - profileWidth) * 0.1
      }
      if (profileWidth > tube.profile_size_y) {
        error2 += Math.abs(tube.profile_size_y - profileWidth)
      } else {
        error2 += Math.abs(tube.profile_size_y - profileWidth) * 0.1
      }
    } else {
      error1 += Math.abs(tube.profile_size_x - profileWidth)
      error2 += Math.abs(tube.profile_size_y - profileWidth)
    }

    if (isHeightOpen) {
      // Profile height is open. Add the full error only if the height of the profile is less than the height of the tube. Otherwise, add a small amount of the error.
      if (profileHeight > tube.profile_size_y) {
        error1 += Math.abs(tube.profile_size_y - profileHeight)
      } else {
        error1 += Math.abs(tube.profile_size_y - profileHeight) * 0.1
      }
      if (profileHeight > tube.profile_size_x) {
        error2 += Math.abs(tube.profile_size_x - profileHeight)
      } else {
        error2 += Math.abs(tube.profile_size_x - profileHeight) * 0.1
      }
    } else {
      error1 += Math.abs(tube.profile_size_y - profileHeight)
      error2 += Math.abs(tube.profile_size_x - profileHeight)
    }

    error += Math.min(error1, error2)

    // Error for incorrect corner radius

    // Corner radius
    if (!roundShapes.includes(tube.shape)) {
      error += (Math.abs((tube.corner_radius ?? 0) - classification.outerRectangle.radius)) / 2
    }

  }

  return error
}


/**
 * Compares two tube classifications to determine whether they are the same, within a small tolerance.
 */
export function compareTubeClassification(classification1: TubeProfileClassification, classification2: TubeProfileClassification) {
  let error = 0.02

  let profileWidth1 = classification1.outerRectangle.bbox.max.x - classification1.outerRectangle.bbox.min.x
  let profileHeight1 = classification1.outerRectangle.bbox.max.y - classification1.outerRectangle.bbox.min.y
  let profileWidth2 = classification2.outerRectangle.bbox.max.x - classification2.outerRectangle.bbox.min.x
  let profileHeight2 = classification2.outerRectangle.bbox.max.y - classification2.outerRectangle.bbox.min.y

  if (classification1.isCircular !== classification2.isCircular) return false
  if (classification1.wallThickness - classification2.wallThickness > error) return false
  if (profileWidth1 - profileWidth2 > error) return false
  if (profileHeight1 - profileHeight2 > error) return false
  if (classification1.outerRectangle.radius - classification2.outerRectangle.radius > error) return false
  return true
}

function pipeSorter(a: CustomerMaterialTube, b: CustomerMaterialTube) {
  if (a.pipe_schedule && b.pipe_schedule && a.pipe_schedule !== b.pipe_schedule) {
    let aInt = parseInt(a.pipe_schedule)
    let bInt = parseInt(b.pipe_schedule)
    return aInt - bInt
  }
  return a.profile_size_x - b.profile_size_x
}


// TODO:

// When the tube catalog loads, determine whether there are any profiles that match the part(s), and remember that
// result. If there are none, iterate through all profiles in the parts, iteratively removing profiles that don't match
// ones of previous parts. This will be used to populate a list of detected profiles, while preventing that list from
// growing too large. If there are none left in the list, say something like "the selected parts have different
// profiles." If there is one or more profiles in the list, say something like "These are the detected profiles:" and 