import { Contour, ErrorItem, NestedPart, TubeProfileClassification, Unit } from '@oshcut/oshlib'
import { ReactNode } from 'react'
import { PartType } from 'types'
import plural, { doesDo, hasHave, isAre, ThisThese, thisThese } from '../../plural'
import unitScale from '../../unitScale'
import { NestPartThumbnails } from '../NestPartThumbnails'
import { UnfoldingTable } from '../UnfoldingTable'
import { isSheetMetal, isTube } from 'partTypeGuards'
import { getMaterialTubeClassificationString, getProfileClassificationString } from 'components/Catalog/TubeListView'
import { Link } from 'react-router-dom'

type FullErrorItem = ErrorItem & {
  header: string,
  category?: string,
  hideInCartView?: boolean,
  body: ReactNode
}

type GetErrorDependencies = {
  handleSelectContours?: (contours: Contour[]) => void
  showMaterialSelectModal?: () => void
  handleUnitsChange?: (units: Unit) => void
  showBendAnimation?: () => void
  show3dView?: () => void
  handleResetPart?: () => void
  unitsSelectComponent?: ReactNode
}

export function getErrorItems(part: PartType, {
  handleSelectContours,
  showMaterialSelectModal,
  handleUnitsChange,
  showBendAnimation,
  show3dView,
  handleResetPart,
  unitsSelectComponent
}: GetErrorDependencies = {}) {

  // Convert part's dfm checks to a flat array, then add with extra goodies
  if (part.dfmStatus !== 'DFM_STATUS_COMPLETE' || !part.dfm) {
    return null
  }
  let items = Object.values(part.dfm).map(operation => {
    return operation.items?.map(i => ({ ...i })) ?? []
  }).flat()

  const arcified = part.arcified
  if (!arcified) return null

  let itemsWithGoodies = items.map((item: ErrorItem): FullErrorItem | null => {

    let n: number, contours: Contour[]

    if (isSheetMetal(arcified) && 'contourIds' in item) {
      contours = arcified.contours.filter(c => item.contourIds.includes(c.id))
    }

    // let defaults = {
    //   header: item.key,
    //   body: <p>{item.key}</p>
    // }

    switch (item.key) {

      case 'missingUnits':
        return {
          ...item,
          header: 'No units selected',
          category: 'size',
          hideInCartView: true,
          body: handleUnitsChange && unitsSelectComponent ? <>
            <p>Please select the units for this part:</p>
            {unitsSelectComponent}
          </> : (
            <p>Please select the units for this part above.</p>
          )
        }
      case 'noMaterial': {
        let body = <p>Please <span className='link' onClick={showMaterialSelectModal}>select a material</span> for this part.</p>

        return {
          ...item,
          header: 'No material selected',
          category: 'material',
          hideInCartView: true,
          body
        }
      }
      case 'unsupportedMaterial':
        return {
          ...item,
          header: 'Material not available',
          category: 'material',
          body: <p>
            The selected material is no longer available.
            {part.isReadonly ?
              <span>Please make a copy of this library part before selecting a new material.</span>
              :
              <span>Please <span className='link' onClick={showMaterialSelectModal}>select a new material</span> for this part.</span>
            }
          </p>
        }
      case 'engraveContoursRemoved':
        return {
          ...item,
          header: 'Engrave contours removed by finishing',
          category: 'finishing',
          body: <>
            <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>one or more engrave contours</span> that will be removed during finishing operations.</p>
            <p>We recommend either removing finishing, or cutting or disabling engrave contours on your part.  Part engraving does not usually remain visible after finishing.</p>
          </>
        }

      case 'engraveContoursCovered':
        return {
          ...item,
          header: 'Engrave contours covered by powder coating',
          category: 'powder',
          body: <>
            <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>one or more engrave contours</span> that will be covered by powder coating.</p>
            <p>We recommend disabling engraved contours on your part.  Part engraving will not remain visible after powder coating.</p>
          </>
        }

      case 'powderAndStitchContours':
        return {
          ...item,
          header: 'Powder coating and stitch cuts',
          category: 'powder',
          body: <>
            <p>Your part will be delivered as shown. Folding along stitch cuts will cause the powder coat to crack and chip.</p>
          </>
        }

      case 'millGrainRemoved':
        return {
          ...item,
          header: 'Mill grain will be removed',
          category: 'finishing',
          body: <p>The material has a surface finish applied at the mill, but that finish will be removed during finishing.  This isn't a problem, but be aware that flat finishing operations will change the look of the part.</p>
        }

      case 'unsupportedDeburringOperation':
        return {
          ...item,
          header: 'Finishing Not Supported',
          category: 'finishing',
          body: isTube(part)
            ? <p>Deburring/finishing is not supported for tube. Please turn off finishing for this part.</p>
            : (part.isReadonly ?
              <p>Deburring/finishing is not supported in the selected material.  Please make a copy of this library part and select a new material, or turn off finishing.</p>
              :
              <p>Deburring/finishing is not supported in the selected material.  Please <span className='link' onClick={showMaterialSelectModal}>select a new material</span>, or turn off finishing for this part.</p>
            )
        }

      case 'tooSmallForFinishing':
        return {
          ...item,
          header: 'Too small for finishing',
          category: 'finishing',
          body: part.isReadonly ?
            <p>Your drawing contains at least one part that is too small to run through the finishing machine.  Please make a copy of this library part and turn off finishing.</p>
            :
            <p>Your drawing contains at least one part that is too small to run through the finishing machine.  Please turn off finishing for this part.</p>
        }

      case 'tooLittleAreaForFinishing':
        return {
          ...item,
          header: 'Too little area for finishing',
          category: 'finishing',
          body: part.isReadonly ?
            <p>Your drawing contains at least one part that has too little surface area to run through the finishing machine.  Please make a copy of this library part and turn off finishing.</p>
            :
            <p>Your drawing contains at least one part that has too little surface area to run through the finishing machine.  Please turn off finishing for this part.</p>
        }

      case 'tooLargeForFinishing':
        return {
          ...item,
          header: 'Too large for finishing',
          category: 'finishing',
          body: part.isReadonly ?
            <p>Your drawing contains at least one part that is too large to run through the finishing machine.  Please make a copy of this library part and turn off finishing.</p>
            :
            <p>Your drawing contains at least one part that is too large to run through the finishing machine.  Please turn off finishing for this part.</p>
        }

      case 'tooHeavyForFinishing':
        return {
          ...item,
          header: 'Too heavy for finishing',
          category: 'finishing',
          body: part.isReadonly ?
            <p>Your drawing contains at least one part that is too heavy to run through the finishing machine.  Please make a copy of this library part and turn off finishing.</p>
            :
            <p>Your drawing contains at least one part that is too heavy to run through the finishing machine.  Please <span className='link' onClick={showMaterialSelectModal}>select a new material</span>, or turn off finishing for this part.</p>
        }

      case 'unsupportedFinishingOperation':
        return {
          ...item,
          header: 'Cannot apply brushed finish',
          category: 'finishing',
          body: part.isReadonly ?
            <p>We can't apply a brushed finish to the selected material. Please make a copy of this library part and select a new material or a different finishing option.</p>
            :
            <p>We can't apply a brushed finish to the selected material.  Please <span className='link' onClick={showMaterialSelectModal}>select a new material</span>, or select a different finishing option.</p>
        }

      case 'unsupportedGrainDirection':
        return {
          ...item,
          header: 'Choose grain direction',
          category: 'finishing',
          body: <p>A brushed finish was selected for your part, but no finishing direction was supplied.  Choose a finishing direction or select a different finishing option to continue.</p>
        }

      case 'tooSmallForHorizontalGrain':
        return {
          ...item,
          header: 'Too small for finishing',
          category: 'finishing',
          body: <p>A horizontal brushed finish was selected for your part, but the part is too narrow to feed into the machine in that direction.  In this material, the minimum part length along the grain direction is {(item.min_finish_feed_length ?? 2.0).toFixed(3)} inches</p>
        }

      case 'tooLargeForHorizontalGrain':
        return {
          ...item,
          header: 'Too large for finishing',
          category: 'finishing',
          body: <p>A horizontal brushed finish was selected for your part, but the part is too large to feed into the machine in that direction.  The maximum part size for finishing is 40 inches</p>
        }

      case 'tooSmallForVerticalGrain':
        return {
          ...item,
          header: 'Too small for finishing',
          category: 'finishing',
          body: <p>A vertical brushed finish was selected for your part, but the part is too narrow to feed into the machine in that direction.  In this material, the minimum part length along the grain direction is {(item.min_finish_feed_length ?? 2.0).toFixed(3)} inches</p>
        }

      case 'tooLargeForVerticalGrain':
        return {
          ...item,
          header: 'Too large for finishing',
          category: 'finishing',
          body: <p>A vertical brushed finish was selected for your part, but the part is too large to feed into the machine in that direction.  The maximum part size for finishing is 40 inches</p>
        }

      case 'nonBendableMaterial':
        return {
          ...item,
          header: 'Cannot bend material',
          category: 'material',
          body: part.isReadonly ?
            <p>We can't bend your selected material. Please make a copy of this library part and select a different material.</p>
            :
            <p>We can't bend your selected material. Please <span className='link' onClick={showMaterialSelectModal}>select a different material</span> for this part.</p>
        }

      case 'unsupportedBendLength':
        return {
          ...item,
          header: 'Bends too long',
          category: 'bend',
          body: <p>Your drawing contains <span className='link' onClick={evt => handleSelectContours?.(contours)}>one or more bends</span> that are longer than supported. The maximum bend length in this material is {item.maxLength?.toFixed(2)} inches.</p>
        }

      case 'multipleOuterContours':
        if (!isSheetMetal(arcified) || !isSheetMetal(part)) return null
        const handleSelectThisAndChildContours = (nestPartDef: NestedPart) => {
          let allOuterContourIds = [nestPartDef.id, ...nestPartDef.equivalentPartIds]
          let contours = arcified.contours.filter(c => allOuterContourIds.includes(c.id) || allOuterContourIds.includes(c.parentId))
          handleSelectContours?.(contours)
        }

        if (part.isLibraryPart) {
          item.type = 'error'
        }

        return {
          ...item,
          header: 'Contains multiple parts',
          category: 'other',
          body: <>
            <p>We found {item.numberOfParts} individual parts ({item.numberOfUniqueParts} unique) in your drawing.</p>
            {part.isLibraryPart
              ? <p>Library parts cannot contain multiple parts.</p>
              : <p>Please double check that the part sizes and quantities are correct.</p>
            }
            <NestPartThumbnails part={part} onClick={handleSelectThisAndChildContours} />
          </>
        }

      case 'unassignedContourCuttingMethod':
        return {
          ...item,
          header: 'Select cutting method',
          category: 'other',
          body: <><p>Your drawing contains one or more open contours. Please <span className='link' onClick={evt => handleSelectContours?.(contours)}>select a processing type</span> for these contours.</p>
          </>
        }

      case 'selfIntersectingContour':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Self-intersecting contour',
          category: 'other',
          body: <>
            <p>Your drawing contains <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} self-intersecting {plural('contour', n)}.</span></p>
          </>
        }

      case 'intersectingContour':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Intersecting contours',
          category: 'other',
          body: <>
            <p>Your drawing contains <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} intersecting {plural('contour', n)}.</span></p>
          </>
        }

      case 'smallPositiveFeatures':
        return {
          ...item,
          header: 'Narrow features',
          category: 'other',
          body: <p>Your drawing contains {item.clusters.length} narrow or sharp {plural('area', item.clusters.length)}.  We can still cut your part, but narrow and sharp features may cause excessive heating, which can reduce edge quality or burn away the material in the area.</p>
          // clusters: positiveClusters.map(cluster => cluster.map(pt => sub(pt, arcified.bbox.min))),
        }

      case 'smallNegativeFeatures':
        return {
          ...item,
          header: 'Small gaps',
          category: 'other',
          body: <p>Your drawing contains {item.clusters.length} {plural('area', item.clusters.length)} that {item.clusters.length > 1 ? 'are' : 'is'} more narrow than recommended for the selected material.  We can still cut your part, but narrow regions may cause excessive heat buildup and affect the edge quality.</p>
          // clusters: negativeClusters.map(cluster => cluster.map(pt => sub(pt, arcified.bbox.min))),
        }

      case 'smallIsthmusFeatures':
        return {
          ...item,
          header: 'Fragile areas',
          category: 'other',
          body: <p>Your drawing contains {item.clusters.length} {plural('area', item.clusters.length)} that may be too fragile to pass through the finishing machine.</p>
          // clusters: isthmusClusters.map(cluster => cluster.map(pt => sub(pt, arcified.bbox.min))),
        }

      case 'orphanedContour':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Orphaned contours',
          category: 'other',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} orphaned {plural('contour', n)}</span> that {isAre(n)} entirely outside of the part.</p>
        }

      case 'sharedContour':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Shared contours',
          category: 'other',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('contour', n)}</span> that {n > 1 ? 'touch' : 'touches'} multiple parts.</p>
        }

      case 'smallContourWarn':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Small holes',
          category: 'other',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('hole', n)}</span> that {isAre(n)} smaller than recommended. We can still cut these features, but very small holes may have reduced edge quality.  The recommended minimum hole size for the selected material is {+(item.minimumPierceAllowanceWarn * unitScale[arcified.units || 'in']).toFixed(3)} {arcified.units}.</p>
        }

      case 'smallContourError':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Small holes',
          category: 'other',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('hole', n)}</span> that {isAre(n)} too small to cut in this material. The minimum hole size for the selected material is {+(item.minimumPierceAllowanceError * unitScale[arcified.units || 'in']).toFixed(3)} {arcified.units}.</p>
        }

      case 'teenyContourWarn':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Tiny contours',
          category: 'other',
          body: <p>Your drawing has {n} {plural('contour', n)} that {isAre(n)} less than 0.01" in size. {ThisThese(n)} could be {n > 1 ? '' : 'an '}{plural('artifact', n)} in your drawing that our system did not automatically remove. Our laser will pierce at {n > 1 ? 'each of these locations' : 'this location'} unless you <span className='link' onClick={evt => handleSelectContours?.(contours)}>ignore {thisThese(n)} {plural('contour', n)}.</span></p>
        }

      case 'engraveThinMaterial':
        return {
          ...item,
          header: 'Cannot engrave material',
          category: 'material',
          body: part.isReadonly ?
            <p>We can't engrave this material because it is too thin. Please make a copy of this library part, then either remove the engraved contours, or choose a different material.</p>
            :
            <p>We can't engrave this material because it is too thin. You can either <span className='link' onClick={evt => handleSelectContours?.(contours)}>remove</span> the engraved contours, or <span className='link' onClick={showMaterialSelectModal}>choose a different material.</span></p>
        }

      case 'veryNarrowStitchTabs':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Narrow stitch tabs',
          category: 'other',
          body: <p>The stitch tabs on <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('contour', n)}</span> are too small. The minimum recommended length in this material is {item.displayMinRecTabLength}{item.displayMinRecTabLengthUnits}. </p>
        }

      case 'narrowStitchTabs':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Narrow stitch tabs',
          category: 'other',
          body: <p>The stitch tabs on <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('contour', n)}</span> are very small. The minimum recommended length in this material is {item.displayMinRecTabLength}{item.displayMinRecTabLengthUnits}. </p>
        }

      case 'badStitchContours':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Colliding stitch cutouts',
          category: 'other',
          body: <p>One or more stitch cutouts on <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('contour', n)}</span> are colliding with other features. You can try adjusting the tab size and spacing to resolve the collisions.</p>
        }

      case 'partTooLarge': {
        let text1 = item.totalNumberOfParts > 1 ?
          (
            item.numberOfAffectedParts > 1 ?
              `Some of your parts are too large.`
              :
              `One of your parts is too large.`
          )
          :
          `Your part is too large.`

        let text2 = item.constrainedByMaterialSize ?
          `The maximum part size in this material is ${+item.maxSheetSize.width.toFixed(3)} ${arcified.units} x ${+item.maxSheetSize.height.toFixed(3)} ${arcified.units}.`
          :
          `Our machines can only cut parts up to ${+item.maxSheetSize.width.toFixed(3)} ${arcified.units} x ${+item.maxSheetSize.height.toFixed(3)} ${arcified.units}.`
        return {
          ...item,
          header: 'Part too large',
          category: 'size',
          body: <p>{text1} {text2}</p>
        }
      }

      case 'partTooLargeWrtGrainDirection': {
        let largeWord = 'large'
        if (item.grainDirection === 'horizontal') {
          largeWord = 'tall'
        }
        if (item.grainDirection === 'vertical') {
          largeWord = 'wide'
        }
        return {
          ...item,
          header: `Too ${largeWord} for ${item.grainDirection} grain`,
          category: 'size',
          body: part.isReadonly ?
            <p>Your part is too {largeWord} to cut with a {item.grainDirection} grain. Please make a copy of this library part, then change the grain direction or reduce the size of the part.</p>
            :
            <p>Your part is too {largeWord} to cut with a {item.grainDirection} grain. Please change the grain direction or reduce the size of your part.</p>
        }
      }

      case 'partTooSmall': {
        let text1 = item.totalNumberOfParts > 1 ?
          (
            item.numberOfAffectedParts > 1 ?
              `Some of your parts are too small to cut.`
              :
              `One of your parts is too small to cut.`
          )
          :
          `Your part is too small to cut.`
        return {
          ...item,
          header: 'Part too small',
          category: 'size',
          body: <p>{text1} The minimum length or width for a part in this material is {item.minPartSingleDimension.toFixed(3)} {arcified.units}, and the minimum part area (length * width) is {item.minPartArea.toFixed(2)} {arcified.units}^2.</p>
        }
      }

      case 'partAspectRatio':
        return {
          ...item,
          header: 'Narrow part',
          category: 'size',
          body: <p>Long, narrow parts often flex and warp during the cut, causing reduced accuracy in both dimensions. Parts may not hold our published +/- 0.005" tolerance across the full length.</p>
        }

      case 'noContours':
        return {
          ...item,
          header: 'Contains no parts',
          category: 'other',
          body: <p>Your drawing contains no parts. A part must have a continuous outer edge to cut.</p>
        }

      case 'bendErrorIntersectingBends':
        return {
          ...item,
          header: 'Intersecting bend lines',
          category: 'bend',
          body: <p>We are unable to bend your part because two or more <span className='link' onClick={evt => handleSelectContours?.(contours)}>bend lines</span> are intersecting.</p>
        }

      case 'bendErrorIncompleteBend':
        return {
          ...item,
          header: 'Incomplete bend line',
          category: 'bend',
          body: <p>We are unable to bend your part because one of the <span className='link' onClick={evt => handleSelectContours?.(contours)}>bend lines</span> does not extend far enough through the part. Bend lines must divide the part into two or more regions.</p>
        }

      case 'bendErrorUnableToBend':
        return {
          ...item,
          header: 'Unable to bend',
          category: 'bend',
          body: <p>We are unable to bend your part due to interfering bend lines.</p>
        }

      case 'bendErrorCannotExtendContour':
        return {
          ...item,
          header: 'Invalid bend line',
          category: 'bend',
          body: <p>One of the <span className='link' onClick={evt => handleSelectContours?.(contours)}>bend lines</span> does not cross the part. You may have to adjust the location or length of the bend line in your CAD software and upload your part again.</p>
        }

      case 'bendErrorNoGaugingSurface':
        return {
          ...item,
          header: 'No gauging surface',
          category: 'bend',
          body: <p>We were unable to find gauging surfaces for one or more <span className='link' onClick={evt => handleSelectContours?.(contours)}>bend lines</span> in your part. Gauging surfaces allow us to align your part on the brake for bending. Try modifying your part to add a straight edge parallel to each bend line.</p>
        }

      case 'bendErrorShortGaugingSurface':
        return {
          ...item,
          header: 'No suitable gauging surface',
          category: 'bend',
          body: <p>We found gauging surfaces for one or more <span className='link' onClick={evt => handleSelectContours?.(contours)}>bend lines</span> in your part, but they are either too short or too far away from the bend to accurately align your part on the brake for bending. Gauging surfaces must be at least {(item.minimumBendGaugingSurfaceLength * unitScale[arcified.units || 'in']).toFixed(1)} {arcified.units} long and no more than {(item.maximumBackGaugeDistance * unitScale[arcified.units || 'in']).toFixed(1)} {arcified.units} from the bend line.</p>
        }

      case 'collidingBendAllowance':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Bends too close together',
          category: 'bend',
          body: <p>Your part has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} bends</span> that are too close together. (If the bends meet at an angle, consider modifying your drawing to add bend relief and re-uploading.)</p>
        }

      case 'collidingBendAllowanceWarning':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Missing bend relief',
          category: 'bend',
          body: <p>Your part has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} bends</span> that meet without bend relief. We can still bend your part, but there may be deformation where the bends meet. Consider modifying your drawing to add bend relief and re-uploading.</p>
        }

      case 'unsupportedBends':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Insufficient flange support',
          category: 'bend',
          body: <>
            <p><span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('bend', n)}</span> {doesDo(n)} not have sufficient flange support on one or both sides of the bend. This can be caused if the bend line is too close to another bend or the edge of the part, or if there are too many internal cutouts inside the minimum flange region.  View our <a href="https://www.oshcut.com/design-guide/metal-bending-design-rules" target="_blank">design rules page</a> for more details.</p>
            {part.isReadonly ?
              <p>Try making a copy of this library part and then decreasing the <b>Inner Bend Radius</b>.</p>
              :
              <p>Try decreasing the <b>Inner Bend Radius</b> of the <span className='link' onClick={evt => handleSelectContours?.(contours)}>bends</span> in your part.</p>
            }
          </>
        }

      case 'unsupportedBendsWarning':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Reduced flange support',
          category: 'bend',
          body: <>
            <p><span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('bend', n)}</span> {hasHave(n)} reduced flange support on one or both sides of the bend. This can be caused if the bend line is too close to another bend or the edge of the part, or if there are too many internal cutouts inside the minimum flange region.  View our <a href="https://www.oshcut.com/design-guide/metal-bending-design-rules" target="_blank">design rules page</a> for more details.</p>
            {!part.isReadonly &&
              <p>You can try decreasing the <b>Inner Bend Radius</b> of the <span className='link' onClick={evt => handleSelectContours?.(contours)}>bends</span> in your part.</p>
            }
          </>
        }

      case 'modelSelfIntersection':
        return {
          ...item,
          header: 'Part self-intersection',
          category: 'bend',
          body: <p>After bending, one or more areas on your part collide (see the affected areas in the <span className='link' onClick={show3dView}>3D view</span>). You may need to adjust bend angles, or redesign your part.</p>
        }

      case 'maxBendAngleError':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Minimum bend angle exceeded',
          category: 'bend',
          body: <p>The minimum bend angle for the selected material is {toConventional(item.maxBendAngle)?.angle} degrees. Your drawing contains <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {n === 1 ? 'bend that exceeds' : 'bends that exceed'} this limit.</span></p>
        }

      case 'invalidBendSequence':
        return {
          ...item,
          header: 'Collision with press brake',
          category: 'bend',
          body: <p>With tooling we currently have available, your part will collide with the press brake during bending. <span className='link' onClick={showBendAnimation}>View simulation</span>.  Our system tries every possibility: if it could not find a sequence without collision, changing the bend order shown in the simulation will not help.</p>
        }

      case 'possibleInvalidBendSequence':
        return {
          header: 'No valid bend sequence found',
          ...item,
          category: 'bend',
          body: <div>
            <p>We couldn't find a valid sequence to bend your part without collision. <span className='link' onClick={showBendAnimation}>View simulation</span>.</p>
            <p>You can try one of the following:</p>
            <ul>
              <li>Simplify your part by reducing the number of bends</li>
              <li>Change some of the bends to stitch cuts so you can bend it by hand after taking delivery of your parts</li>
              <li>Split your part into two or more simpler pieces, which could be welded or fastened together</li>
            </ul>
          </div>
        }

      case 'invalidBendRadiusManualValue':
        return {
          ...item,
          header: 'Invalid bend radius',
          category: 'bend',
          body: <p>Your chosen value for the Inner Bend Radius is invalid. Please <span className='link' onClick={evt => handleSelectContours?.([contours[0]])}>choose another option</span>, or select "Automatic". </p>
        }

      case 'tooHeavyForBending':
        return {
          ...item,
          header: 'Too heavy for bending',
          category: 'bend',
          body: <p>Your part is too heavy to be manually fed to our press brakes. The maximum weight for bending is currently {item.maxWeightLb} lb ({(item.maxWeightLb * 0.45359237).toFixed(1)} kg).</p>
        }

      case 'bendOverTonnage':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Bends too long',
          category: 'bend',
          body: <p>Your part has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} {plural('bend', n)}</span> that exceed{n === 1 ? 's' : ''} the tonnage limit for the selected material and bend radius. The maximum bend length for the selected material and bend radius is {+item.maxLength.toFixed(2)}". Please try <span className='link' onClick={evt => handleSelectContours?.(contours)}>increasing the bend radius</span>, or choose a thinner material.</p>
        }

      case 'openCutsDividePart':
        return {
          ...item,
          header: 'Cut divides part',
          category: 'other',
          body: <p>One or more cuts divide your part into multiple pieces. If this is intentional, please submit each individual part separately.</p>
        }

      case 'holesWithoutFinishedDiameter':
        return {
          ...item,
          header: 'Select hole diameter',
          category: 'other',
          body: <p>Please select a diameter for <span className='link' onClick={evt => handleSelectContours?.(contours)}>these holes.</span></p>
        }

      case 'holesWithoutThread':
        return {
          ...item,
          header: 'Select thread',
          category: 'tap',
          body: <p>Please select a thread for <span className='link' onClick={evt => handleSelectContours?.(contours)}>these holes.</span></p>
        }

      case 'tappedHolesInKeepOutRegion':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Holes too close to bend',
          category: 'tap',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} tapped {plural('hole', n)}</span> that {isAre(n)} too close to a bend. A tapped hole may not intersect the bend keep out region, otherwise the bending process will deform the threads.</p>
        }

      case 'tappedHolesTooClose':
        n = item.contourIds.length
        if (item.type === 'error') {
          return {
            ...item,
            header: 'Holes too close to edge',
            category: 'tap',
            body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} tapped {plural('hole', n)}</span> that {isAre(n)} too close to the edge of the part or another feature. The major diameter of the hole must not intersect another contour, otherwise the thread cutting operation will break through the material.</p>
          }
        } else {
          return {
            ...item,
            header: 'Holes close to edge',
            category: 'tap',
            body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} tapped {plural('hole', n)}</span> that {isAre(n)} very close to the edge of the part or another feature. The major diameter of the hole should not be within 0.05" of another contour, otherwise the thread cutting operation may break through the material.</p>
          }
        }

      case 'tappedHolesTooShallow':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Low thread count',
          category: 'tap',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} tapped {plural('hole', n)}</span> that {hasHave(n)} fewer than one-and-a-half threads. Consider using a thicker material or a thread with a finer pitch.</p>
        }

      case 'tappedHolesTooShallowWarn':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Low thread count',
          category: 'tap',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} tapped {plural('hole', n)}</span> that {hasHave(n)} fewer than three threads. Consider using a thicker material or a thread with a finer pitch.</p>
        }

      case 'tappedHolesTooDeep':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Holes too deep',
          category: 'tap',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} tapped {plural('hole', n)}</span> that {isAre(n)} too deep. The maximum material thickness is three times the major diameter of the thread.</p>
        }

      case 'tappedHolesThatAreNotHoles':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Invalid tapped hole',
          category: 'tap',
          body: <p>Your drawing has <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} tapped {plural('hole', n)}</span> that cannot be tapped. Tapped holes must be closed, inner contours.</p>
        }

      case 'tappingNotSupportedInMaterial':
        return {
          ...item,
          header: 'Tapping not supported',
          category: 'tap',
          body: <p>Tapping is not supported in your selected material.</p>
        }

      case 'tappedHolesThreadUnavailable':
        n = item.contourIds.length
        return {
          ...item,
          header: 'Thread unavailable',
          category: 'tap',
          body: <p>The thread selected for <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} of your holes</span> is no longer available.</p>
        }

      case 'tappingPartTooHeavy':
        return {
          ...item,
          header: 'Part too heavy for tapping',
          category: 'tap',
          body: <p>Your part is too heavy to be tapped. The maximum weight for tapping is {item.maxWeightLb} lb ({(item.maxWeightLb * 0.45359237).toFixed(1)} kg).</p>
        }

      case 'beadBlastingPartTooHeavy':
        return {
          ...item,
          header: 'Part too heavy for bead blasting',
          category: 'finishing',
          body: <p>Your part is too heavy to be bead blasted. The maximum weight for bead blasting is {item.maxWeightLb} lb ({(item.maxWeightLb * 0.45359237).toFixed(1)} kg).</p>
        }

      case 'beadBlastingPartTooLarge':
      case 'beadBlastingPartTooLargeArea':
        return {
          ...item,
          header: 'Part too large for bead blasting',
          category: 'finishing',
          body: <p>Your part is too large to be bead blasted. The surface area (front and back) must be less than 8 sq. ft., and the part must be no larger than 120" x 84" x 36" inches.</p>
        }

      case 'beadBlastingPartTooSmall':
        return {
          ...item,
          header: 'Part too small for bead blasting',
          category: 'finishing',
          body: <p>Your part is too small to be bead blasted. Your part must be at least 2" long in any dimension.</p>
        }

      case 'beadBlastingNotSupportedInMaterial':
        return {
          ...item,
          header: 'Bead blasting not supported',
          category: 'finishing',
          body: <p>Bead blasting is not supported in your selected material.</p>
        }

      case 'tumblingPartTooHeavy':
        return {
          ...item,
          header: 'Part too heavy for tumbling',
          category: 'finishing',
          body: <p>Your part is too heavy for tumbling. The maximum weight for tumbling is {item.maxWeightLb} lb ({(item.maxWeightLb * 0.45359237).toFixed(1)} kg).</p>
        }

      case 'tumblingPartTooLarge':
        return {
          ...item,
          header: 'Part too large for tumbling',
          category: 'finishing',
          body: <p>Your part is too large for tumbling. Your part must fit inside a cylinder roughly {item.maxHeightInches} inches tall and {item.maxDiameterInches} inches in diameter.</p>
        }

      case 'tumblingNotSupportedInMaterial':
        return {
          ...item,
          header: 'Tumbling not supported',
          category: 'finishing',
          body: <p>Tumbling is not supported in your selected material.</p>
        }

      case 'countersinkNotSupported':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Countersinking not offered yet',
          category: 'other',
          body: <>
            <p>We found <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} countersunk {plural('hole', n)}</span> in your part. We don't offer countersinking yet, so your part will be delivered with {thisThese(n)} {plural('hole', n)} NOT countersunk.</p>
          </>
        }

      case 'counterboreNotSupported':
        n = item.contourIds?.length
        return {
          ...item,
          header: 'Counterboring not supported',
          category: 'other',
          body: <>
            <p>We found <span className='link' onClick={evt => handleSelectContours?.(contours)}>{n} counterbored {plural('hole', n)}</span> in your part. We don't support counterboring, so your part will be delivered with {thisThese(n)} {plural('hole', n)} NOT counterbored. We will only cut the smallest diameter of any counterbored holes.</p>
          </>
        }

      case 'tooLargeForPowder':
        return {
          ...item,
          header: 'Too large for powder coating',
          category: 'powder',
          body: <p>Your part is too large to be powder coated. The maximum size is 60 inches in each dimension.</p>
        }

      case 'tooHeavyForPowder':
        return {
          ...item,
          header: 'Too heavy for powder coating',
          category: 'powder',
          body: <p>Your part is too heavy to be powder coated. The maximum weight for powder coating is {item.maxWeightLb} lb ({(item.maxWeightLb * 0.45359237).toFixed(1)} kg).</p>
        }

      case 'noPowderColorSelected':
        return {
          ...item,
          header: 'Select powder color',
          category: 'powder',
          body: <p>Please select a powder color.</p>
        }

      case 'unsupportedMaterialForPowder':
        return {
          ...item,
          header: 'Powder coating not supported',
          category: 'powder',
          body: part.isReadonly ?
            <p>Powder coating is not supported in the selected material.  Please make a copy of this library part, then select a new material or turn off powder coating.</p>
            :
            <p>Powder coating is not supported in the selected material.  Please <span className='link' onClick={showMaterialSelectModal}>select a new material</span>, or turn off powder coating for this part.</p>
        }

      case 'noHangPoint':
        return {
          ...item,
          header: 'Cannot hang for powder coating',
          category: 'powder',
          body: <p>We couldn't figure out how to hang your part for powder coating. There must be a hole at least 0.125" in diameter and within 1.5" of the edge of the part, or a hook-shaped feature, to hang the part from during powder coating.</p>
        }

      case 'burnoutRisk':
        let category = 'other'
        let header
        let body
        switch (item.type) {
          case 'warn':
            switch (item.degree) {
              case 'extreme':
                header = 'Extreme risk of overheating'
                body = <p>Some areas of your part have an extreme risk of overheating during the cut. This most commonly occurs in sharp or narrow features, and often causes the cut edge to blow out or produce excessive burr.</p>
                break
              case 'severe':
                header = 'Severe risk of overheating'
                body = <p>Some areas of your part have a severe risk of overheating during the cut. This most commonly occurs in sharp or narrow features, and often causes the cut edge to blow out or produce excessive burr.</p>
                break
              case 'moderate':
                header = 'Moderate risk of overheating'
                body = <p>Some areas of your part have a moderate risk of overheating during the cut. This most commonly occurs in sharp or narrow features, and often causes the cut edge to blow out or produce excessive burr.</p>
                break
            }
            break
          case 'error':
            switch (item.degree) {
              case 'extreme':
                header = 'Extreme risk of overheating'
                body = <p>A large amount of your part is subject to extreme risk of overheating during the cut. This can cause the material to warp, collect excessive burr, and/or blow out along cut edges.</p>
                break
              case 'severe':
                header = 'Severe risk of overheating'
                body = <p>A large amount of your part is subject to severe risk of overheating during the cut. This can cause the material to warp, collect excessive burr, and/or blow out along cut edges.</p>
                break
              case 'moderate':
                header = 'High risk of overheating'
                body = <p>A large amount of your part is subject to moderate or higher risk of overheating during the cut. This can cause the material to warp, collect excessive burr, and/or blow out along cut edges.</p>
                break
            }
            break
        }
        return {
          ...item,
          header,
          category,
          body
        }

      case 'unfoldSizeError': {
        if (!isSheetMetal(part)) return null

        let {
          hasThicknessError,
          hasMultipleInnerRadii,
          hasInnerRadiiError,
          wasCompensationApplied,
          compensationNotNeeded
        } = item.unfoldCheck

        let component = UnfoldingTable(part, item.unfoldCheck)?.component

        let troubleshootingTips = []


        troubleshootingTips.push(<li>Compare the current part with your original uploaded model in the <span className='link' onClick={show3dView}>3D view</span>. If the two parts look identical, you may choose to ignore this warning.</li>)

        if (hasThicknessError) {
          troubleshootingTips.push(<li>Make sure you have chosen a material that matches the thickness of your uploaded model.</li>)
        }

        if (Math.abs((arcified.scaleFactor ?? 1) - 1) > 1e-4 || arcified.units !== arcified.dxfUnits) {
          troubleshootingTips.push(<li>Make sure you have not changed the size or units of the part.</li>)
        }

        if (part.arcified.contours.some(c => {
          return (
            c.hasOwnProperty('bendAngle') && c.hasOwnProperty('unfoldBendAngle') && c.bendAngle !== c.unfoldBendAngle
            || c.hasOwnProperty('unfoldBendAngle') && c.cuttingMethod !== 'CUTTING_METHOD_BEND'
          )
        })) {
          troubleshootingTips.push(<li>Make sure you have not changed the angle, direction, or processing type of any bends.</li>)
        }

        const bendInfo = part.arcified.bendInfoV5 ?? part.arcified.bendInfo
        if ((hasMultipleInnerRadii || hasInnerRadiiError) && !wasCompensationApplied && !compensationNotNeeded && bendInfo) {
          let thicknessUnit: Unit = 'in', digits = 3
          if (['cm', 'mm', 'm'].includes(arcified.units ?? 'in')) {
            thicknessUnit = 'mm'
            digits = 2
          }
          let correctBendRadius = (bendInfo.innerRadiusInch * unitScale[thicknessUnit]).toFixed(digits)
          // TODO: Also suggest choosing a different bend radius, if that option is available
          troubleshootingTips.push(<li>Try redesigning your part with an inner bend radius of {correctBendRadius} {thicknessUnit}.</li>)
        }

        return {
          ...item,
          header: '3D model warning',
          category: 'other',
          body: <>
            <p>Your 3D uploaded model may not have been processed correctly. There are <span className='error bold'>discrepancies</span> between this part and your uploaded model:</p>

            {component}
            <br />
            {troubleshootingTips.length > 0 && <div>
              <p>Troubleshooting:</p>
              <ul>
                {troubleshootingTips}
              </ul>
            </div>
            }
            <p>OSH Cut's automatic sheet metal unfolding service is experimental. If the troubleshooting steps above do not help, you can try to <span className='link' onClick={handleResetPart}>reset</span> your part, or report a problem.</p>
          </>
        }
      }

      case 'bendRadiiAdjusted': {
        if (!isSheetMetal(part)) return null
        let component = UnfoldingTable(part, item.unfoldCheck)?.component
        return {
          ...item,
          header: 'Bend radii adjusted',
          category: 'other',
          body: <>
            <p>We automatically modified your model's bend radii to match our tooling&mdash;see the table below for details.</p>
            {component}
          </>
        }
      }

      case 'bendUnstretchableOpenCutContours': {
        if (!isSheetMetal(part)) return null
        return {
          ...item,
          header: 'Cannot adjust bend radii',
          category: 'bend',
          body: <p>We couldn't automatically adjust your part's bend radii because it contains open cut contours. Please <span className='link' onClick={evt => handleSelectContours?.(contours)}>set the processing type of these contours to "Ignore".</span></p>
        }
      }

      case 'tubeTooLong': {
        if (!isTube(part)) return null
        return {
          ...item,
          header: 'Part too long',
          category: 'size',
          body: <p>The maximum part length in this material is {+item.maxTubeLength.toFixed(3)} inches.</p>
        }
      }

      case 'tubeProfileMismatch': {
        if (!isTube(part)) return null
        let classifications = part.arcified.tubeProfilingSolutions.map(s => s.classification).filter((c): c is TubeProfileClassification => c != null)

        const p1 = <p>The selected material does not match the profile of your uploaded part.</p>

        const p2 = classifications.length > 0 && part.arcified.materialTube && <>
          <p><b>Detected {plural('profile', classifications.length)}:</b>
            <ul>
              {classifications.map(c => <li>{getProfileClassificationString(c)}</li>)}
            </ul>
          </p>
          <p><b>Selected profile:</b>
            <ul>
              <li>{getMaterialTubeClassificationString(part.arcified.materialTube)}</li>
            </ul>
          </p>
        </>

        const span1 = part.isReadonly ?
          <span>
            Please make a copy of this library part and select a different material. Or,
          </span>
          :
          <span>
            Please <span className='link' onClick={showMaterialSelectModal}>select a different material</span> for this part, or
          </span>

        const span2 = <span>
          redesign your part with the correct profile. You can download DXF profiles of all our tubes in the material catalog.
          For more information, see <a href='https://www.oshcut.com/design-guide/how-to-order-laser-cut-tube' target='_blank'>How to Order Laser-Cut Tube</a>.
        </span>

        const p3 = <p>{span1} {span2}</p>

        return {
          ...item,
          header: 'Profile mismatch',
          category: 'material',
          body: <>{p1} {p2} {p3}</>
        }
      }

      case 'tubeBevelAngleTooHigh': {
        if (!isTube(part)) return null
        let n = item.cutPathIndexes.length
        return {
          ...item,
          header: 'Cutting angle too steep',
          category: 'other',
          body: <p>The cutting angle (bevel) of {n} {plural('contour', n)} exceeds 45 degrees. The maximum cutting angle is 45 degrees.</p>
        }
      }

      case 'tubeCostingError': {
        // if (!isTube(part)) return null
        return {
          ...item,
          header: 'Could not quote',
          category: 'other',
          body: <p>There was an error while calculating the price of this part. Please contact us for assistance.</p>
        }
      }

      default:
        return {
          // @ts-expect-error item will be never, but we want to output the unknown key anyway
          ...item,
          header: 'Error',
          category: 'other',
          // @ts-expect-error item will be never, but we want to output the unknown key anyway
          body: <p>Error code: {item.key}</p>
        }
    }
  })
    .filter((item): item is FullErrorItem => item != null)

  if (part.isLibraryPart && part.libraryPart?.item.customer_archived) {
    itemsWithGoodies.push({
      // @ts-ignore - This is a custom key
      key: 'archivedLibraryPart',
      type: 'error',
      header: 'Library part archived',
      category: 'other',
      body: <p>This library part has been archived and cannot be ordered. To unarchive this part, please visit the <Link to={`/account/library/item/${part.libraryPart?.item.id}`}>Part Library.</Link></p>
    })
  }

  return itemsWithGoodies
}


/**
 * Converts an angle from Oshcut's internal format to the conventional format.
 * @param {Number} angle Bend angle in Oshcut's internal format
 * @returns {Object} An object containing properties angle and direction in the conventional format.
 */
function toConventional(angle: number) {
  // 0 angle should result in direction of 1
  if (angle == null) return null
  return { angle: 180 - Math.abs(angle), direction: Math.sign(angle) || 1 }
}