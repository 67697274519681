import { BlockPlaceholder, Dialog, LinkButton, LoadingSpinner, PrimaryButton, SecondaryButton } from '@oshcut/components'
import {
  CustomerPaymentMethod,
  LibraryPart,
  OrderForCustomer,
  OrderPart,
  PipeStatus,
  PipeStatusForOrdersApi,
  SalesInvoice,
  Shipment,
  fetchPost,
  formatCents,
} from '@oshcut/oshlib'
import { useLoadingOverlay } from 'hooks/useLoadingOverlay'
import { DateTime } from 'luxon'
import { getPercentCompletionMulti } from 'pipeStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { DispatchFunction } from 'reducer'
import { CartPart, ParsedOrder, StateType } from 'types'
import { formatCityStateZip, getTrackingURL, ItemToAdd, loadCartPartsForOrder } from '../../util'
import { useAddPartsToCurrentOrder } from 'hooks/useAddPartsToCurrentOrder'
import Card from '../Card'
import { getErrorText } from '../InvoicesView/OpenInvoices'
import OrderPartQtyBoxes from './OrderPartQtyBoxes'
import OrderPartsTable from './OrderPartsTable'
import OrderStatusTag from './OrderStatusTag'
import '../css/OrderDetails.scss'
import { useClientWidth } from 'hooks/useClientWidth'
import OrderPartsTableMobile from './OrderPartsTableMobile'
import { PaymentMethodsProvider } from '../PaymentMethod/PaymentMethodsContext'
import { useSignIn } from '../../useSignIn'
import { PaymentMethodSelector } from '../PaymentMethod/PaymentMethodSelector'
import { PaymentMethodCard } from '../PaymentMethod/PaymentMethodCard'
import { humanReadableInvoiceTerms } from '../../util'
import { Link, useHistory } from 'react-router-dom'

type CertDetail = {
  item_id: number
  material_sheet_id: number
  material_tube_id: number
  description: string
  file_ids: string[]
}

type OrderDetailsSlideoverProps = {
  state: StateType
  dispatch: DispatchFunction
  order: ParsedOrder<OrderForCustomer>
  onOrderUpdate?: (order: ParsedOrder<OrderForCustomer>) => void
  readonly?: boolean
}

const OrderDetails = ({ state, dispatch, order, onOrderUpdate, readonly = false }: OrderDetailsSlideoverProps) => {
  const history = useHistory()

  const { customer, customerUser } = useSignIn()
  const clientWidth = useClientWidth()

  const [errors, setErrors] = useState<string[]>([])
  const [isCancelDialogVisible, setIsCancelDialogVisible] = useState<boolean>(false)
  const [cancelError, setCancelError] = useState<string>('')

  const [cartParts, setCartParts] = useState<CartPart[] | null>(null)
  const [orderingCustomerUserName, setOrderingCustomerUserName] = useState<string | undefined>()

  const { addPartsToCurrentOrder, overlayContent: addPartsOverlayContent } = useAddPartsToCurrentOrder({
    state,
    dispatch,
    onError: e => setErrors(cv => [...cv, e]),
  })

  const [customerPaymentMethod, setCustomerPaymentMethod] = useState<CustomerPaymentMethod | null>(null)

  // Load the cart parts for the order
  useEffect(() => {
    loadCartPartsForOrder(order, state.materialTypes, state.materialSheets, state.materialTubes, state.powders)
      .then(cp => {
        setCartParts(cp)
      })
      .catch(e => {
        setErrors(cv => [...cv, e.message])
      })
  }, [order])

  const [pipeStatuses, setPipeStatuses] = useState<PipeStatusForOrdersApi.PipeStatusForOrderPart[]>()
  const [certs, setCerts] = useState<CertDetail[]>()

  const fetchPipeStatuses = useCallback(async () => {
    try {
      const ps = await fetchPost('/api/v2/pipe_status/for_order_parts_of_order', { order_id: order.id })
      setPipeStatuses(ps)
    } catch (_) {
      setErrors(cv => [...cv, 'Failed to fetch progress for this order.'])
    }
  }, [setPipeStatuses, setErrors, order])

  const fetchCertsInfo = useCallback(async () => {
    try {
      const result = (await fetchPost('/api/v2/order/certs', { guid: order.guid })) as { certs: CertDetail[] }
      setCerts(result.certs)
    } catch (_) {
      setErrors(cv => [...cv, 'Failed to fetch certs for this order.'])
    }
  }, [order])

  const orderBelongsToCurrentCustomerUser = useMemo(() => order.customer_user_id === customerUser?.id, [order.customer_user_id, customerUser])

  // Fetch pipe status and certs on load
  useEffect(() => {
    fetchPipeStatuses()
    fetchCertsInfo()
  }, [fetchPipeStatuses, fetchCertsInfo])

  useEffect(() => {
    if (!order.customer_payment_method_guid || !orderBelongsToCurrentCustomerUser) {
      setCustomerPaymentMethod(null)
    } else {
      fetchPost('/api/v2/payment_method/get', { guid: order.customer_payment_method_guid })
        .then(result => setCustomerPaymentMethod(result.paymentMethod))
        .catch(e => setErrors(cv => [...cv, 'Failed to get payment method for this order.']))
    }
  }, [order.customer_payment_method_guid, orderBelongsToCurrentCustomerUser])
  
  useEffect(() => {
    if(!customer) return
    if(!!orderingCustomerUserName) return
    
    if(orderBelongsToCurrentCustomerUser && customerUser) {
      setOrderingCustomerUserName(`${customerUser.first_name} ${customerUser.last_name}`)
    } else {
      fetchPost('/api/v3/customer_user/for_authorized_customer', { customer_id: customer.id, })
        .then(httpResult => httpResult.result[0].data)
        .then(customerUsers => customerUsers.find(cu => cu.id === order.customer_user_id))
        .then(otherCustomerUser => {
          if(otherCustomerUser) setOrderingCustomerUserName(`${otherCustomerUser.first_name} ${otherCustomerUser.last_name}`)
        })
    }
  }, [order.customer_user_id, customer,  orderBelongsToCurrentCustomerUser, orderingCustomerUserName, customerUser])

  /** Order Parts with pipe status rolled up */
  const cartPartsWithPipeStatus = useMemo(() => {
    if (!cartParts || !pipeStatuses) return
    return cartParts.map(op => {
      const myPipeStatus = pipeStatuses.find(ps => ps.order_part_id === op.orderPart.id)?.pipe_status
      return {
        ...op,
        pipeStatus: myPipeStatus,
      } as CartPart & { pipeStatus: PipeStatus[] | undefined }
    })
  }, [cartParts, pipeStatuses])

  const [salesInvoices, setSalesInvoices] = useState<SalesInvoice[] | null>(null)

  useEffect(() => {
    if (!order.use_invoices) return

    fetchPost('/api/v2/sales_invoice/get_for_order', { order_id: order.id })
      .then(result => setSalesInvoices(result.salesInvoices))
      .catch(e => setErrors(cv => [...cv, 'Failed to get invoices for this order.']))
  }, [order.id])

  const [overlayContent, showLoadingOverlay, hideLoadingOverlay] = useLoadingOverlay()

  async function handleAddToCart(itemsToAdd: ItemToAdd[]) {
    addPartsToCurrentOrder(itemsToAdd)
  }

  async function handleAddAllToCart() {
    if (!cartParts) return
    let itemsToAdd: ItemToAdd[] = cartParts
      .filter(part => part.id?.indexOf('P-') === 0)
      .map(cp => ({
        orderPart: cp.orderPart,
        libraryPart: cp.libraryPart,
        qty: cp.orderPart.qty,
      }))
    handleAddToCart(itemsToAdd)
  }

  const isSavedCart = order.status === 'saved'

  async function handleTitleSave(newTitle: string) {
    showLoadingOverlay('Saving your order')
    try {
      let updatedOrder = await fetchPost('/api/v2/order/update', {
        guid: order.guid,
        description: newTitle,
      })
      onOrderUpdate?.(updatedOrder.order)
    } catch (ex) {
      console.error(ex)
      setErrors(cv => [...cv, "We couldn't save changes to your cart. Please contact support for assistance."])
    }
    hideLoadingOverlay()
  }
  async function handleToggleStar(starred: boolean) {
    try {
      let updatedOrder = await fetchPost('/api/v2/order/update', {
        guid: order.guid,
        customer_starred: starred,
      })
      onOrderUpdate?.(updatedOrder.order)
    } catch (e) {
      console.error(e)
      setErrors(cv => [...cv, "We couldn't save changes to your cart. Please contact support for assistance."])
    }
  }

  function onAddToPartLibrary(libraryPart: LibraryPart, orderPart: OrderPart) {
    // Update local state with the new library part and order part
    setCartParts(prev => {
      if (!prev) return prev
      return prev.map(cp => {
        if (cp.orderPart.id === orderPart.id) {
          return {
            ...cp,
            orderPart,
            libraryPart,
            isLibraryPart: true,
            name: orderPart.name,
          }
        }
        return cp
      })
    })
  }

  const paymentMethod: CustomerPaymentMethod | null = useMemo(() => {
    if (customerPaymentMethod) return customerPaymentMethod
    return !!order.card_ending ? ({ last4: order.card_ending, type: 'credit_card' } as CustomerPaymentMethod) : null
  }, [order, customerPaymentMethod])

  const [shipments, setShipments] = useState<(Shipment & { friendly_method: string })[]>()
  const [shipmentsAreLoading, setShipmentsAreLoading] = useState(false)
  const [shipmentFetchError, setShipmentFetchError] = useState<string>()

  const canChangePaymentMethod = useMemo(() => {
    if (!customer?.ff_checkout_v2) return false
    if (!salesInvoices || salesInvoices.length > 0) return false
    if(!orderBelongsToCurrentCustomerUser) return false
    return [
      'draft',
      'hold_feedback',
      'hold_material',
      'hold_payment',
      'hold_pickup',
      'hold_review',
      'processing',
      'quoted',
    ].includes(order.status)
  }, [order.status, salesInvoices, customer?.ff_checkout_v2, orderBelongsToCurrentCustomerUser])

  const isQuote = order.status === 'quoted'

  function tryFetchShipments() {
    setShipmentsAreLoading(true)
    setShipmentFetchError(undefined)
    setShipments(undefined)
    fetchPost('/api/v2/shipment/get_for_order', { order_id: order.id })
      .then(apiShipments => {
        const filteredShipments = apiShipments.filter(s => s.status !== 'draft' && s.carrier !== 'Pickup')
        setShipments(filteredShipments)
      })
      .catch(err => {
        console.error(err)
        setShipmentFetchError(getErrorText(err))
      })
      .finally(() => setShipmentsAreLoading(false))
  }

  useEffect(() => {
    tryFetchShipments()
  }, [order])

  function updatePaymentMethod(paymentMethod: CustomerPaymentMethod) {
    fetchPost('/api/v2/order/update_payment_method', {
      order_guid: order.guid,
      customer_payment_method_guid: paymentMethod.guid,
    }).then(result => {
      onOrderUpdate?.(result.updatedOrder)
    })
  }

  function cancelQuote() {
    fetchPost('/api/v2/order/quote/cancel', { order_guid: order.guid }).then(result => {
      onOrderUpdate?.(result.order)
      setIsCancelDialogVisible(false)
    }).catch(e => {
      setCancelError(`Error: ${getErrorText(e)}`)
    })
  }

  function navigatePlaceOrder() {
    history.push(`/order/quote/${order.guid}`)
  }

  return (
    <div className='OrderDetails'>
      {errors.length > 0 && (
        <div className='errors'>
          {errors.map((e, i) => (
            <div key={i} className='error'>
              <FaExclamationTriangle />
              <span>{e}</span>
            </div>
          ))}
        </div>
      )}

      {!isSavedCart && (
        <div className='details'>
          {/* Main Details Section */}
          <Card
            className='mainDetails'
            title={
              <>
                <h1>{order.internal_id || 'Untitled Order'}</h1>
                <OrderStatusTag status={order.status} fontSize={18} />
                {isQuote && <LinkButton onClick={()=> setIsCancelDialogVisible(true)}> Cancel Quote </LinkButton>}
              </>
            }
          >
            <div className='dates'>
              {!!order.quote_date && (
                <>
                  <p>Quoted on {DateTime.fromMillis(order.quote_date).toFormat('DDD')}</p>
                  <p>{!!order.rush_level && `${getRushText(order.rush_level)}`}</p>
                </>
              )}
              {order.status !== 'quoted' && (
                <>
                  <p>Placed on <b>{DateTime.fromMillis(order.order_date).toFormat('DDD')}</b>{!!orderingCustomerUserName && ` by ${orderingCustomerUserName}`}</p>
                  <p>Due on or before <b>{DateTime.fromMillis(order.target_ship_date).toFormat('DDD')}</b> {!!order.rush_level && `(${getRushText(order.rush_level)})`}</p>
                </>
              )}
            </div>
            {(order.invoice_terms === 'due_on_receipt' || order.invoice_terms == null) && !isQuote && (
              <>
                {canChangePaymentMethod ? (
                  <div className='payment'>
                    {!!customer && !!customerUser && (
                      <PaymentMethodsProvider customer={customer} customerUser={customerUser}>
                        <PaymentMethodSelector
                          paymentMethod={paymentMethod}
                          onPaymentMethodSelection={updatePaymentMethod}
                          showHeader
                          flatCard
                          dialogTitleOverride={'Change Payment Method'}
                        />
                      </PaymentMethodsProvider>
                    )}
                    <div>This payment method will be used when your order is invoiced.</div>
                  </div>
                ) : (
                  <div className='payment'>
                    <p>
                      <b>Payment Method</b>
                    </p>
                    {!!paymentMethod ? (
                      <PaymentMethodCard paymentMethod={paymentMethod} noBoxShadow />
                    ) : (
                      'No payment method selected'
                    )}
                  </div>
                )}
              </>
            )}

            {isQuote && (
              <div>
                <Link className='quote-place-order' to={`/order/quote/${order.guid}`}>
                  <PrimaryButton>Place Order...</PrimaryButton>
                </Link>
              </div>
            )}

            {order.invoice_terms != null && order.invoice_terms !== 'due_on_receipt' && (
              <div className='payment'>
                <p>
                  <b>Payment Terms</b>
                </p>
                <p>{`${humanReadableInvoiceTerms(order.invoice_terms)}`}</p>
              </div>
            )}

            <div className='shipTo'>
              {order.is_pickup === 1 ? (
                <>
                  <div className='orderDetailsLabel'>Pickup Contact</div>
                  <div>{order.ship_name}</div>
                  <div>Phone: {order.phone}</div>
                </>
              ) : (
                <>
                  <div className='orderDetailsLabel'>Ship To</div>
                  <div>{order.ship_company}</div>
                  <div>{order.ship_name}</div>
                  <div>{order.phone}</div>
                  <div>{order.ship_address}</div>
                  <div>{order.ship_address2}</div>
                  <div>{order.ship_address3}</div>
                  <div>{formatCityStateZip(order.ship_city, order.ship_state, order.ship_zip)}</div>
                  <div>{order.ship_country}</div>
                </>
              )}
            </div>

            {order.status !== 'quoted' && (
              <div className='billTo'>
                <div className='orderDetailsLabel'>Bill To</div>
                <div>{order.bill_company}</div>
                <div>{order.bill_name}</div>
                <div>{order.bill_phone}</div>
                <div>{order.bill_address}</div>
                <div>{order.bill_address2}</div>
                <div>{order.bill_address3}</div>
                <div>{formatCityStateZip(order.bill_city, order.bill_state, order.bill_zip)}</div>
                <div>{order.bill_country}</div>
              </div>
            )}

            <div className='ack'>
              {order.status === 'quoted' && <a href={`/api/v2/order/quote/pdf?order_guid=${order.guid}`} target='_blank'>
                Quote PDF
              </a>}
              
              {order.status !== 'quoted' && order.status !== 'cancelled' && <a href={`/api/v2/order/acknowledgment/pdf?id=${order.id}`} target='_blank'>
                Order Acknowledgment
              </a>}
            </div>
          </Card>

          {/* Shipments, invoices and production status */}
          <Card className='shipmentsInvoicesProduction'>
            {/* Shipments */}
            <div className='shipmentsContainer'>
              <div className='orderDetailsLabel'>Shipments</div>
              <div className='shipments'>
                {shipmentsAreLoading ? (
                  <>
                    <BlockPlaceholder style={{ height: 16, borderRadius: 3, marginBottom: 1 }} />
                    <BlockPlaceholder style={{ height: 30, borderRadius: 3, marginBottom: 1 }} />
                  </>
                ) : (
                  <>
                    {/* Shipments loaded. If more than zero, display each one. */}
                    {shipments ? (
                      shipments.length > 0 ? (
                        <>
                          <div className='shipment header'>
                            <span>Method</span>
                            <span>Tracking No.</span>
                            <span>Packing List</span>
                          </div>
                          {shipments.map(shipment => {
                            const trackingURL = getTrackingURL(shipment.carrier, shipment.tracking)
                            return (
                              <div className='shipment' key={shipment.id}>
                                <span>
                                  {shipment.carrier} {shipment.friendly_method}
                                </span>
                                <span>
                                  {trackingURL ? (
                                    <a href={trackingURL ?? ''} target='_blank' rel='noopener noreferrer'>
                                      {shipment.tracking}
                                    </a>
                                  ) : (
                                    shipment.tracking
                                  )}
                                </span>
                                {/* <span>{shipment.tracking}</span> */}
                                <span>
                                  <a href={`/api/v2/shipment/pack_list?shipment_id=${shipment.id}`} target='_blank'>
                                    Packing List
                                  </a>
                                </span>
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        <p>None found.</p>
                      )
                    ) : null}

                    {/* Fetch call failed with some error. */}
                    {shipmentFetchError ? (
                      <>
                        <p className='error'>Failed to fetch shipments.</p>
                        <LinkButton onClick={tryFetchShipments}>Try again</LinkButton>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>

            {/* Invoices */}
            <div className='invoicesContainer'>
              <div className='orderDetailsLabel'>Invoices</div>
              <div className='invoices'>
                {!!order.use_invoices &&
                  (salesInvoices != null ? (
                    <>
                      {salesInvoices.length > 0 &&
                        salesInvoices
                          .filter(si => si.status !== 'voided' && si.status !== 'draft')
                          .map(si => {
                            const dtNow = DateTime.fromMillis(Date.now())
                            const dtDue = si.due_date != null ? DateTime.fromMillis(si.due_date) : null
                            const dayDifference = dtDue != null ? Math.ceil(dtDue.diff(dtNow, 'days').days) : null
                            const daysPastDue =
                              dayDifference != null && dayDifference < 0 ? Math.abs(dayDifference) : null
                            const isPastDue =
                              si.status === 'approved' &&
                              si.outstanding_balance_cents > 0 &&
                              daysPastDue &&
                              daysPastDue > 0

                            return (
                              <div className='invoice' key={si.id}>
                                <div>
                                  <a href={`/api/v2/sales_invoice/pdf?id=${si.id}`} target='_blank'>
                                    {si.invoice_number}
                                  </a>
                                </div>
                                <div className={isPastDue ? 'pastDue' : ''}>
                                  {si.outstanding_balance_cents === 0
                                    ? '(Paid)'
                                    : `(${formatCents(si.outstanding_balance_cents)} due by ${DateTime.fromMillis(si.due_date).toFormat('DDD')})`}
                                </div>
                              </div>
                            )
                          })}

                      {salesInvoices.length == 0 && <>Not yet invoiced.</>}
                    </>
                  ) : (
                    <LoadingSpinner />
                  ))}

                {!order.use_invoices &&
                  (order.status === 'shipped' ? (
                    <a href={`/api/v1/order/invoice?id=${order.id}`} target='_blank'>
                      {order.internal_id || 'Untitled Order'}
                    </a>
                  ) : (
                    'Not yet invoiced.'
                  ))}
              </div>
            </div>

            {/* Production */}
            {!['draft', 'saved', 'cancelled', 'quoted', 'shipped'].includes(order.status) ? (
              <div className='productionContainer'>
                <div className='orderDetailsLabel'>Production Status</div>
                {pipeStatuses ? (
                  <OrderPartQtyBoxes data={getPercentCompletionMulti(pipeStatuses)} />
                ) : (
                  <BlockPlaceholder style={{ height: 45, width: 200, borderRadius: 5 }} />
                )}
              </div>
            ) : (
              <></>
            )}

            {/* Certs */}
            {certs && certs.length > 0 ? (
              <div className='certsContainer'>
                <div className='orderDetailsLabel'>Mill Certs</div>
                <div className='certs'>
                  {certs.map(cert => (
                    <div className='cert' key={cert.item_id}>
                      {cert.file_ids.length === 1 && (
                        <div>
                          <a href={`/api/v2/attachment/download?file_id=${cert.file_ids[0]}`} target='_blank'>
                            {cert.description}
                          </a>
                        </div>
                      )}
                      {cert.file_ids.length > 1 && (
                        <>
                          <div>{cert.description} (multiple certs)</div>
                          {cert.file_ids.map((file_id, i) => (
                            <div className='certHeatOption' key={i}>
                              <a href={`/api/v2/attachment/download?file_id=${file_id}`} target='_blank'>
                                Download #{i + 1}
                              </a>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </Card>
        </div>
      )}

      {/* Parts table */}
      {cartPartsWithPipeStatus ? (
        <Card className='parts'>
          {clientWidth >= 1000 && (
            <OrderPartsTable
              order={order}
              cartPartsWithPipeStatus={cartPartsWithPipeStatus}
              onAddToCart={handleAddToCart}
              onSavedCardTitleUpdate={handleTitleSave}
              onToggleStar={handleToggleStar}
              onAddAllToCart={handleAddAllToCart}
              readonly={readonly}
              onAddToPartLibrary={onAddToPartLibrary}
            />
          )}

          {clientWidth < 1000 && (
            <OrderPartsTableMobile
              order={order}
              cartPartsWithPipeStatus={cartPartsWithPipeStatus}
              onAddToCart={handleAddToCart}
              onSavedCardTitleUpdate={handleTitleSave}
              onToggleStar={handleToggleStar}
              onAddAllToCart={handleAddAllToCart}
              readonly={readonly}
              onAddToPartLibrary={onAddToPartLibrary}
            />
          )}
        </Card>
      ) : (
        <BlockPlaceholder style={{ height: 400, borderRadius: 5 }} />
      )}

      {/* FAQs section (only for in-progress orders) */}
      {!['draft', 'saved', 'cancelled', 'shipped'].includes(order.status) && (
        <div className='faqs'>
          <div className='faq'>
            <b>When Will My Order Be Ready?</b>
            <p>
              Your order should ship by its due date, but we often ship orders early. We'll let you know by email when
              your order ships.
            </p>
          </div>
          <div className='faq'>
            <b>What If I Want to Make a Change?</b>
            <p>
              You can generally request changes to your order as long as it hasn't been released to production. We may
              start your order early, so please let us know of any required changes as quickly as you can.
            </p>
            <p>
              You can contact us by replying to your order confirmation email, or you can email us at{' '}
              <a href='mailto:support@oshcut.com'>support@oshcut.com</a>. You can also call our office at 801-850-7584.
            </p>
          </div>
          <div className='faq'>
            <b>When Will I Be Charged?</b>
            <p>
              Your order will be charged just before it ships. If your card declines, your order will be held until the
              payment issue is corrected.
            </p>
          </div>
        </div>
      )}
      {isCancelDialogVisible && <Dialog title="Cancel Quote" open onClose={()=> setIsCancelDialogVisible(false)}>
        <div className="cancel-quote-dialog">
          <p>Are you sure you want to cancel this quote?</p>
          <div className="cancel-quote-dialog-actions">
            <PrimaryButton disabled={!!cancelError} onClick={cancelQuote}>Yes</PrimaryButton>
            <SecondaryButton onClick={()=> setIsCancelDialogVisible(false)}>Go Back</SecondaryButton>
          </div>
          {!!cancelError && <div className='error'>{cancelError}</div>}
        </div>
      </Dialog>}
      {overlayContent}
      {addPartsOverlayContent}
    </div>
  )
}


function getRushText(rushLevel: OrderForCustomer['rush_level']) {
  switch (rushLevel) {
    case 'priority': return 'Priority Rush'
    case 'expedited': return 'Expedited lead'
    default: return 'Standard lead'
  }
}

export default OrderDetails
