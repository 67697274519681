import { Arcified, ArcifiedTube, ArcifiedUnknown, Contour, CustomerMaterialSheet, CustomerMaterialTube, CustomerMaterialType, LibraryPart, Hardware, OrderForCustomer, OrderLatestQuote, OrderPart, Part, Powder, Setting, Tap, Unit, Vector, OrderNestingResult, PostResponseType } from "@oshcut/oshlib"
import update from "immutability-helper"
import { DfmStatus, ParsedOrder, PartDfm, PartStatus, PartType, SelectedContour, StateType } from "types"
import Log from "./logs"
import { isSheetMetal } from "partTypeGuards"

export type DispatchFunction = (action: ActionType) => void

type ActionType =
  | {
    type: 'ACTION_ALL_MATERIALS_LOADED',
    materialSheets: CustomerMaterialSheet[],
    materialTypes: CustomerMaterialType[],
    materialTubes: CustomerMaterialTube[],
  }
  | { type: 'ACTION_ALL_POWDERS_LOADED', powders: Powder[] }
  | { type: 'ACTION_ALL_TAPS_LOADED', taps: Tap[] }
  | { type: 'ACTION_ALL_HARDWARE_LOADED', hardware: Hardware[] }
  | { type: 'ACTION_MESSAGES_LOADED', messages: Setting[] }
  | { type: 'ACTION_CLEAR_CART' }
  | { type: 'ACTION_SET_SUBMITTED_ORDER', order: ParsedOrder<OrderForCustomer> | null }
  | { type: 'ACTION_SET_BILLING_EQUALS_SHIPPING', billingEqualsShipping: boolean }
  | { type: 'ACTION_CREATE_PART', part: PartType, partIdToReplace?: Part['id'] }
  | { type: 'ACTION_CREATE_ORDER_PART', order_part: OrderPart }
  | { type: 'ACTION_REMOVE_PART', partId: Part['id'] }
  | { type: 'ACTION_REMOVE_PARTS', partIds: Part['id'][] }
  | { type: 'ACTION_REMOVE_ORDER_PART', partId: Part['id'] }
  | { type: 'ACTION_REMOVE_ORDER_PARTS', partIds: Part['id'][] }
  | { type: 'ACTION_REMOVE_ALL_PARTS' }
  | { type: 'ACTION_UNREMOVE_PART', partId: Part['id'] }
  | {
    type: 'ACTION_SET_PART_STATUS',
    partId: Part['id'],
    status?: PartStatus,
    dfmStatus?: DfmStatus,
    statusProgress?: number | null,
    dfmStatusProgress?: number | null,
    message?: string
    dfmMessage?: string
  }
  | { type: 'ACTION_LIBRARY_PART_LOADED', libraryPart: LibraryPart }
  | { type: 'ACTION_LIBRARY_PARTS_UPDATED', libraryParts: LibraryPart[] }
  | { type: 'ACTION_ARCIFIED_LOADED', partId: Part['id'], arcified: Arcified | ArcifiedTube | ArcifiedUnknown | undefined }
  | { type: 'ACTION_DFM_LOADED', partId: Part['id'], dfm: PartDfm }
  | { type: 'ACTION_SELECT_CONTOURS', contours: SelectedContour[] | SelectedContour }
  | { type: 'ACTION_DESELECT_CONTOURS' }
  | { type: 'ACTION_TOGGLE_SELECT_CONTOURS', contours: SelectedContour[] | SelectedContour }
  | { type: 'ACTION_SELECT_CONTOURS_WITH_FILTER', partId: Part['id'], filter: ({ contour }: { contour: Contour }) => boolean }
  | { type: 'ACTION_SELECT_SIMILAR_CONTOURS', partId: Part['id'] }
  | { type: 'ACTION_SET_SELECTED_PARTS', partIds: Part['id'][] }
  | { type: 'ACTION_SET_UI_SELECTED_CONTOUR_PROPERTIES', partId: Part['id'] }
  | { type: 'ACTION_UPDATE_CONTOUR_PROPERTIES', partId: Part['id'], propertyName: keyof Contour, propertyValue: any, contours: { contourId: Contour['id'] }[] }
  | { type: 'ACTION_SET_CUSTOM_SIZE', partId: Part['id'], value: number }
  | { type: 'ACTION_SET_UNITS', partId: Part['id'], value: Unit | undefined }
  | { type: 'ACTION_SET_PART_PROPERTY', partId: Part['id'], propName: keyof Arcified | keyof ArcifiedTube, propValue: any }
  | { type: 'ACTION_SET_MATERIAL', partId: Part['id'], material: (CustomerMaterialSheet & CustomerMaterialType) | undefined }
  | { type: 'ACTION_SET_MATERIAL_TUBE', partId: Part['id'], material: (CustomerMaterialTube & CustomerMaterialType) | undefined }
  | { type: 'ACTION_SET_QUANTITY', partId: Part['id'] | string | null, quantity: number }
  | { type: 'ACTION_SET_QUANTITY_VALID', partId: Part['id'], quantityValid: boolean }
  | { type: 'ACTION_SHOW_ERROR_PANEL' }
  | { type: 'ACTION_HIDE_ERROR_PANEL' }
  | { type: 'ACTION_SET_ALERT_POINTS', alertPoints: Vector[] }
  | { type: 'ACTION_UNSET_ALERT_POINTS' }
  | { type: 'ACTION_TOGGLE_EXPAND_PART_INFO_ITEM', key: string }
  | { type: 'ACTION_BEGIN_HOVER_PART_INFO_ITEM', key: string }
  | { type: 'ACTION_END_HOVER_PART_INFO_ITEM', key: string }
  | { type: 'ACTION_INVALIDATE_QUOTE' }
  | { type: 'ACTION_SET_QUOTE', quote?: OrderLatestQuote | null, isQuoteNeeded?: boolean, isQuoteValid?: boolean, isFetchingQuote?: boolean }
  | { type: 'ACTION_SET_NESTING_RESULT', nestingResult: PostResponseType<'/api/v2/nest'>['result'] | null }
  | { type: 'ACTION_SET_PART_NAME', partId: Part['id'], name: string }
  | { type: 'ACTION_UPDATE_ORDER_PART', order_part: OrderPart }
  | { type: 'ACTION_ORDER_LOADED', order: ParsedOrder<OrderForCustomer> | null }
  | { type: 'ACTION_ORDER_MERGE', order: Partial<ParsedOrder> }
  | { type: 'ACTION_ORDER_PARTS_LOADED', order_parts: OrderPart[] }
  | { type: 'ACTION_SHOW_LINK_ANIMATOR', key: string, message: string }
  | { type: 'ACTION_HIDE_LINK_ANIMATOR', key: string }
  | { type: 'ACTION_TOGGLE_COLOR_CODE_BENDS' }
  | { type: 'ACTION_TOGGLE_SHOW_BEND_ALLOWANCE' }
  | { type: 'ACTION_TOGGLE_NO_BEND_TRANSFORMS' }
  | { type: 'ACTION_TOGGLE_SHOW_CONVEX_DECOMPOSITION' }
  | { type: 'ACTION_TOGGLE_SHOW_STACKED' }
  | { type: 'ACTION_SET_PERCENT_BEND_TRANSFORM', value: number }
  | { type: 'ACTION_SET_BEND_ADVANCED_EXPANDED', value: boolean }
  | { type: 'ACTION_TOGGLE_SHOW_TUBE_DEBUG_HINTS' }
  | { type: 'ACTION_DISMISS_MOBILE_WARNING' }
  | { type: 'ACTION_TOGGLE_USE_V5_BEND_INFO' }


export function reducer(state: StateType, action: ActionType): StateType {
  Log.info(action)

  function getPart(partId: Part['id']) {
    let part = state.parts.find(p => p.id === partId)
    if (!part) {
      throw new Error('Cannot find part with id ' + partId)
    }
    return part
  }

  function getPartIndex(partId: Part['id']) {
    if (!partId) {
      throw new Error('partId is required')
    }
    let partIndex = state.parts.findIndex(p => p.id === partId)
    if (partIndex === -1) {
      throw new Error('Cannot find part with id ' + partId)
    }
    return partIndex
  }

  switch (action.type) {

    case 'ACTION_ALL_MATERIALS_LOADED': {
      return update(state, {
        materialSheets: { $set: action.materialSheets },
        materialTypes: { $set: action.materialTypes },
        materialTubes: { $set: action.materialTubes },
      })
    }

    case 'ACTION_ALL_POWDERS_LOADED': {
      let powders = action.powders.sort((a, b) => a.display_order - b.display_order)

      return update(state, { powders: { $set: powders } })
    }

    case 'ACTION_ALL_TAPS_LOADED': {
      let taps = action.taps.filter(t => t.deleted === 0)
      return update(state, { taps: { $set: taps } })
    }

    case 'ACTION_ALL_HARDWARE_LOADED': {
      return update(state, { hardware: { $set: action.hardware } })
    }

    case 'ACTION_MESSAGES_LOADED': {
      return update(state, { messages: { $set: action.messages } })
    }

    case 'ACTION_CLEAR_CART': {
      return update(state, {
        order: { $set: null },
        parts: { $set: [] },
        order_parts: { $set: [] },
        selectedPartIds: { $set: [] },
        selectedContours: { $set: [] },
        quoteInvalidationTimestamp: { $set: 0 }
      })
    }

    case 'ACTION_SET_SUBMITTED_ORDER': {
      return update(state, { submittedOrder: { $set: action.order } })
    }

    case 'ACTION_SET_BILLING_EQUALS_SHIPPING': {
      return update(state, { billingEqualsShipping: { $set: action.billingEqualsShipping } })
    }

    /**
     * Pushes a new part onto state.parts, giving it a display order according to its position in the array. If a part
     * with the given id already exists, or if partIdToReplace is set, it is replaced with the new part.
     */
    case 'ACTION_CREATE_PART': {
      let partIndex = state.parts.findIndex(p => p.id === (action.partIdToReplace ?? action.part.id))
      if (partIndex >= 0) {
        return update(state, { parts: { [partIndex]: { $set: action.part } } })
      } else {
        return update(state, { parts: { $push: [action.part] } })
      }
    }

    case 'ACTION_CREATE_ORDER_PART': {
      return update(state, { order_parts: { $push: [action.order_part] } })
    }

    case 'ACTION_REMOVE_PART': {

      // Mark part as deleted
      let partIndex = getPartIndex(action.partId)
      let stateUpdateObject: any = { parts: { [partIndex]: { deleted: { $set: true } } } }

      // Remove the part from the selection
      let idx = state.selectedPartIds.indexOf(action.partId)
      if (idx >= 0) {
        stateUpdateObject.selectedPartIds = { $splice: [[idx, 1]] }
      }

      return update(state, stateUpdateObject)
      //return update(state, { parts: { $splice: [[partIndex, 1]] } })
    }

    case 'ACTION_REMOVE_PARTS': {
      // Mark parts as deleted
      let stateUpdateObject: any = { parts: {} }
      for (let partId of action.partIds) {
        let partIndex = state.parts.findIndex(p => p.id === partId)
        stateUpdateObject.parts[partIndex] = { deleted: { $set: true } }
      }

      // Remove the parts from the selection
      stateUpdateObject.selectedPartIds = { $set: state.selectedPartIds.filter(id => !action.partIds.includes(id)) }
      return update(state, stateUpdateObject)
    }

    case 'ACTION_REMOVE_ORDER_PART': {
      // Remove the order_part
      let part = getPart(action.partId)
      let order_part_idx = state.order_parts.findIndex(op => op.part_id === part.id)
      return update(state, { order_parts: { $splice: [[order_part_idx, 1]] } })
    }

    case 'ACTION_REMOVE_ORDER_PARTS': {
      // Remove the specified order_parts
      return update(state, { order_parts: { $set: state.order_parts.filter(op => action.partIds.indexOf(op.part_id as Part['id']) < 0) } })
    }


    case 'ACTION_REMOVE_ALL_PARTS': {
      // This time we're actually removing all parts
      return update(state, { parts: { $set: [] } })
    }

    case 'ACTION_UNREMOVE_PART': {
      let partIndex = getPartIndex(action.partId)
      // Mark part as not deleted
      let stateUpdateObject = { parts: { [partIndex]: { deleted: { $set: false } } } }

      return update(state, stateUpdateObject)
    }

    /** Changes the status of a part */
    case 'ACTION_SET_PART_STATUS': {
      let partIndex = getPartIndex(action.partId)
      let stateUpdateObject: any = {
        parts: {
          [partIndex]: {}
        }
      }

      if (action.status === 'PART_STATUS_READY') {
        action.statusProgress = null
      }

      if (action.dfmStatus === 'DFM_STATUS_COMPLETE') {
        action.dfmStatusProgress = null
      }

      let allowedKeys = ['status', 'message', 'statusProgress', 'dfmStatus', 'dfmMessage', 'dfmStatusProgress'] as const
      for (let key of allowedKeys) {
        if (action.hasOwnProperty(key)) stateUpdateObject.parts[partIndex][key] = { $set: action[key] }
      }


      return update(state, stateUpdateObject)
    }

    case 'ACTION_LIBRARY_PART_LOADED': {
      let partIndex = getPartIndex(action.libraryPart.item.part_id as Part['id'])
      return update(state, {
        parts: {
          [partIndex]: {
            libraryPart: { $set: action.libraryPart },
            isLibraryPart: { $set: !!action.libraryPart.item.customer_part_number },
          }
        }
      })
    }

    case 'ACTION_LIBRARY_PARTS_UPDATED': {
      let stateUpdateObject: any = { parts: {} }
      for (let libraryPart of action.libraryParts) {
        let partIndex = state.parts.findIndex(p => p.id === libraryPart.item.part_id)
        if (partIndex >= 0) {
          stateUpdateObject.parts[partIndex] = {
            libraryPart: { $set: libraryPart },
            isLibraryPart: { $set: !!libraryPart.item.customer_part_number },
          }
        }
      }
      return update(state, stateUpdateObject)
    }

    case 'ACTION_ARCIFIED_LOADED': {

      // Add missing contour properties
      let partIndex = getPartIndex(action.partId)
      let stateUpdateObject: any = { parts: { [partIndex]: { arcified: { contours: {} } } } }

      if (action.arcified && !action.arcified.scaleFactor) {
        action.arcified.scaleFactor = 1
      }

      stateUpdateObject.parts[partIndex].arcified = { $set: action.arcified }

      Log.info(stateUpdateObject)

      return update(state, stateUpdateObject)
    }

    case 'ACTION_DFM_LOADED': {
      let partIndex = getPartIndex(action.partId)
      let stateUpdateObject = { parts: { [partIndex]: { dfm: { $set: action.dfm } } } }
      return update(state, stateUpdateObject)
    }

    /**
     * Will update state.selectedContours
     */
    case 'ACTION_SELECT_CONTOURS': {
      if (!action.contours) {
        throw new Error('In ACTION_SELECT_CONTOURS, action.contours is required')
      }
      if (!Array.isArray(action.contours)) {
        action.contours = [action.contours]
      }

      let newSelectedContours: SelectedContour[] = []
      for (let sc of action.contours) {
        const part = state.parts.find(p => p.id === sc.partId)
        if (!isSheetMetal(part)) continue
        let contour = part.arcified?.contours.find(c => c.id === sc.contourId)
        if (!contour) continue
        if (contour.autoGenerated) {
          // Replace this contour with the one it was generated from
          contour = part.arcified.contours.find(c => c.id === contour!.autoGeneratedOriginContourId)
        }
        if (!contour) continue
        if (newSelectedContours.some(sc2 => sc2.partId === part.id && sc2.contourId === contour!.id)) continue
        newSelectedContours.push({ partId: part.id, contourId: contour.id })
      }

      return update(state, { selectedContours: { $set: newSelectedContours } })
    }

    /**
     * Will update state.selectedContours
     */
    case 'ACTION_DESELECT_CONTOURS': {
      return update(state, { selectedContours: { $set: [] } })
    }

    /**
     * Will update state.selectedContours
     */
    case 'ACTION_TOGGLE_SELECT_CONTOURS': {

      if (!action.contours) {
        throw new Error('In ACTION_TOGGLE_SELECT_CONTOURS, action.contours is required')
      }
      if (!Array.isArray(action.contours)) {
        action.contours = [action.contours]
      }

      // Don't allow direct selection of generated contours. For each generated contour, replace it with the one it was
      // generated from.
      let contsToggled: SelectedContour[] = []
      action.contours.forEach(c => {
        const part = state.parts.find(p => p.id === c.partId)
        if (!isSheetMetal(part)) return
        let contour = part.arcified?.contours.find(c2 => c2.id === c.contourId)
        if (!contour) return
        if (contour.autoGenerated) {
          contour = part.arcified.contours.find(c2 => c2.id === contour!.autoGeneratedOriginContourId)
        }
        if (!contour) return
        if (!contsToggled.some(c2 => c2.partId === part.id && c2.contourId === contour!.id)) {
          contsToggled.push({ partId: part.id, contourId: contour.id })
        }
      })

      let contsToSplice: [number, number][] = []
      let contsToPush: SelectedContour[] = []

      contsToggled.forEach(c => {
        let idx = state.selectedContours.findIndex(c2 => c.contourId === c2.contourId)
        if (idx >= 0) {
          contsToSplice.push([idx, 1])
        } else {
          contsToPush.push(c)
        }
      })

      // Need to sort contsToSplice in reverse order so indices don't change
      contsToSplice.sort((a, b) => a[0] > b[0] ? -1 : 1)

      return update(state, {
        selectedContours: {
          $splice: contsToSplice,
          $push: contsToPush
        }
      })
    }

    /**
     * For the given part, will select all contours that match some predicate, deselecting any other contours that may be selected.
     * filter is the predicate function. It receives a single argument containing the following properties: contour
     */
    case 'ACTION_SELECT_CONTOURS_WITH_FILTER': {

      let contsToSplice: [number, number][] = []
      let contsToPush: SelectedContour[] = []

      // Push additional matching contours that are not selected
      const part = getPart(action.partId)
      if (!isSheetMetal(part)) return state
      part.arcified.contours.forEach(contour => {

        let matchesPredicate = action.filter({ contour })
        if (matchesPredicate) {
          if (!state.selectedContours.some(sc => sc.contourId === contour.id)) {
            contsToPush.push({ partId: part.id, contourId: contour.id })
          }
        }
      })

      // For each selectedContour, splice it if it does not match
      state.selectedContours.forEach((sc, scIdx) => {
        let contour = part.arcified.contours.find(c => c.id === sc.contourId)
        if (!contour) return
        let matchesPredicate = action.filter({ contour })
        if (!matchesPredicate) {
          contsToSplice.push([scIdx, 1])
        }
      })

      // Need to sort contsToSplice in reverse order so indices don't change
      contsToSplice.sort((a, b) => a[0] > b[0] ? -1 : 1)

      return update(state, {
        selectedContours: {
          $splice: contsToSplice,
          $push: contsToPush
        }
      })

    }

    /**
     * For all selected contours, will select other contours that are similar. Only contours in the same part can be considered similar.
     */
    case 'ACTION_SELECT_SIMILAR_CONTOURS': {

      let firstSimilarContours = new Set<Contour['id']>()
      const part = getPart(action.partId)
      if (!isSheetMetal(part)) return state
      state.selectedContours.forEach(sc => {

        let contour = part.arcified.contours.find(c => c.id === sc.contourId)
        if (!contour) return
        let firstSimilarContourId
        if (contour.hasSimilar) {
          firstSimilarContourId = contour.id
        } else if (contour.hasOwnProperty('similarTo')) {
          firstSimilarContourId = contour.similarTo
        } else {
          // Not similar to anything, but don't unselect it
          firstSimilarContourId = contour.id
        }

        if (firstSimilarContourId != null) {
          firstSimilarContours.add(firstSimilarContourId)
        }
      })

      let newSelectedContours: SelectedContour[] = []

      for (let cid of firstSimilarContours) {
        newSelectedContours.push({ partId: part.id, contourId: cid })
      }
      part.arcified.contours.forEach(c => {
        if (c.similarTo != null && firstSimilarContours.has(c.similarTo)) {
          newSelectedContours.push({ partId: part.id, contourId: c.id })
        }
      })

      return update(state, {
        selectedContours: { $set: newSelectedContours }
      })
    }

    /**
     * Set the selected parts, and set the UI selected part properties.
     */
    case 'ACTION_SET_SELECTED_PARTS': {
      return update(state, {
        selectedPartIds: { $set: action.partIds }
      })
    }

    /**
     * Loads a limited set of properties of the selected contours (`state.selectedContours`) into `state.previewSelectedContourProperties`.
     * You would normally call this immediately after calling ACTION_SELECT_CONTOURS.
     * You might also call this action alone if reverting changes to contours (but want to keep editing).
     * If any properties differ between contours, they are set to null.
     */
    case 'ACTION_SET_UI_SELECTED_CONTOUR_PROPERTIES': {

      // These will start as undefined. If they are null, that means leadins had different properties.
      //let props = ['length', 'radius', 'angle', 'microjoint', 'cuttingMethod', 'cuttingDirection', 'cutterCompensation', 'contourSize']
      let props = [
        'cuttingMethod',
        'bendAngle',
        'stitchTabLength',
        'stitchPatternLength',
        'bendRadiusOverride',
        'bendKFactorOverride',
        'holeType', // 'HOLE_TYPE_STANDARD' (default if undefined), 'HOLE_TYPE_DRILL', 'HOLE_TYPE_REAM', 'HOLE_TYPE_TAP'
        'holeFinishedDiameter', // The finished diameter of the hole
        'holeThread', // string such as '1/4 - 20' or 'M3 x 0.5'
        'hardwareId'
      ] as const
      let propVals: Partial<Record<keyof Contour, any>> = {}
      const part = getPart(action.partId)
      if (!isSheetMetal(part)) return state
      state.selectedContours.forEach(c => {
        const contour = part.arcified.contours.find(c2 => c2.id === c.contourId)
        if (!contour) return
        props.forEach(key => {
          let contourProp = contour[key]
          switch (key) {
            case 'holeType':
              if (contourProp === undefined) {
                contourProp = 'HOLE_TYPE_STANDARD'
              }
              break
            case 'holeThread':
              if (contourProp === undefined) {
                contourProp = ''
              }
              break
            case 'cuttingMethod':
              // Hide the fact that hole contours will have cuttingMethod of CUTTING_METHOD_HOLE. Show them as having CUTTING_METHOD_CUT.
              if (contourProp === 'CUTTING_METHOD_HOLE') {
                contourProp = 'CUTTING_METHOD_CUT'
              }
              break
          }

          if (typeof propVals[key] === 'undefined') {
            propVals[key] = contourProp
          } else if (contourProp !== propVals[key]) {
            propVals[key] = null
          }
        })
      })

      Log.info(propVals)

      return update(state, {
        previewSelectedContourProperties: { $set: propVals }
      })
    }

    /**
       * Updates each specified contour property to the provided value.
       */
    case 'ACTION_UPDATE_CONTOUR_PROPERTIES': {
      let partIndex = getPartIndex(action.partId)
      const part = state.parts[partIndex]
      let stateUpdateObject: any = {
        parts: { [partIndex]: { arcified: { contours: {} } } }
      }

      if (!action.contours) {
        throw new Error('In ACTION_UPDATE_CONTOUR_PROPERTIES, action.contours is required')
      }

      if (!isSheetMetal(part)) return state

      // Log.info(action)

      let propName = action.propertyName
      let propValue = action.propertyValue

      action.contours.forEach(c => {
        // Log.info(c)

        let contourIdx = part.arcified.contours.findIndex(c2 => c2.id === c.contourId)
        if (contourIdx < 0) {
          throw new Error('Could not find index of this contour')
        }

        stateUpdateObject.parts[partIndex].arcified.contours[contourIdx] = { [propName]: { $set: propValue } }
      })

      // Log.info(stateUpdateObject)
      return update(state, stateUpdateObject)
    }

    case 'ACTION_SET_CUSTOM_SIZE': {
      let partIndex = getPartIndex(action.partId)
      return update(state, { parts: { [partIndex]: { arcified: { scaleFactor: { $set: action.value } } } } })
    }

    case 'ACTION_SET_UNITS': {
      let partIndex = getPartIndex(action.partId)
      if (state.parts[partIndex]?.arcified) {
        return update(state, { parts: { [partIndex]: { arcified: { units: { $set: action.value } } } } })
      } else {
        return state
      }
    }

    case 'ACTION_SET_PART_PROPERTY': {
      let partIdx = getPartIndex(action.partId)
      return update(state, { parts: { [partIdx]: { arcified: { [action.propName]: { $set: action.propValue } } } } })
    }

    case 'ACTION_SET_MATERIAL': {

      let newRecentMaterialIds = state.recentMaterialIds.slice()
      let partIndex = getPartIndex(action.partId)
      if (action.material && !newRecentMaterialIds.includes(action.material.id)) {
        newRecentMaterialIds.splice(0, 0, action.material.id)
        if (newRecentMaterialIds.length > 5) {
          newRecentMaterialIds.pop()
        }
      }
      return update(state, {
        parts: { [partIndex]: { arcified: { material: { $set: action.material } } } },
        recentMaterialIds: { $set: newRecentMaterialIds }
      })
    }

    case 'ACTION_SET_MATERIAL_TUBE': {

      let newRecentMaterialTubeIds = state.recentMaterialTubeIds.slice()
      let partIndex = getPartIndex(action.partId)
      if (action.material && !newRecentMaterialTubeIds.includes(action.material.id)) {
        newRecentMaterialTubeIds.splice(0, 0, action.material.id)
        if (newRecentMaterialTubeIds.length > 5) {
          newRecentMaterialTubeIds.pop()
        }
      }
      return update(state, {
        parts: { [partIndex]: { arcified: { materialTube: { $set: action.material } } } },
        recentMaterialTubeIds: { $set: newRecentMaterialTubeIds }
      })
    }

    case 'ACTION_SET_QUANTITY': {
      let partIndex = getPartIndex(action.partId as Part['id'])
      return update(state, { parts: { [partIndex]: { quantity: { $set: action.quantity } } } })
    }

    case 'ACTION_SET_QUANTITY_VALID': {
      let partIndex = getPartIndex(action.partId)
      return update(state, { parts: { [partIndex]: { quantityValid: { $set: action.quantityValid } } } })
    }

    case 'ACTION_SHOW_ERROR_PANEL': {
      return update(state, { isErrorPanelVisible: { $set: true } })
    }

    case 'ACTION_HIDE_ERROR_PANEL': {
      return update(state, { isErrorPanelVisible: { $set: false } })
    }

    case 'ACTION_SET_ALERT_POINTS': {
      return update(state, { alertPoints: { $set: action.alertPoints } })
    }

    case 'ACTION_UNSET_ALERT_POINTS': {
      return update(state, { $unset: ['alertPoints'] })
    }

    case 'ACTION_TOGGLE_EXPAND_PART_INFO_ITEM': {
      let idx = state.expandedPartInfoKeys.indexOf(action.key)
      if (idx >= 0) {
        return update(state, { expandedPartInfoKeys: { $splice: [[idx, 1]] } })
      } else {
        return update(state, { expandedPartInfoKeys: { $set: [action.key] } })  // Only allow one open at a time
        // return update(state, { expandedPartInfoKeys: { $push: [action.key] } })  // Allow any number open at a time
      }

    }

    case 'ACTION_BEGIN_HOVER_PART_INFO_ITEM': {
      return update(state, { hoverPartInfoKey: { $set: action.key } })
    }

    case 'ACTION_END_HOVER_PART_INFO_ITEM': {
      // Avoid removing the hoverPartInfoKey if a hover begin was fired before a hover end
      if (state.hoverPartInfoKey === action.key) {
        return update(state, { $unset: ['hoverPartInfoKey'] })
      } else {
        return state
      }
    }

    case 'ACTION_INVALIDATE_QUOTE': {
      // HACK: Date.now() makes this an impure reducer
      return update(state, { quoteInvalidationTimestamp: { $set: Date.now() } })
    }

    case 'ACTION_SET_QUOTE': {
      let updateObject: any = {}
      if (action.quote !== undefined) {
        updateObject.quote = { $set: action.quote }
      }
      if (action.isQuoteNeeded !== undefined) {
        updateObject.isQuoteNeeded = { $set: action.isQuoteNeeded }
      }
      if (action.isQuoteValid !== undefined) {
        updateObject.isQuoteValid = { $set: action.isQuoteValid }
      }
      if (action.isFetchingQuote !== undefined) {
        updateObject.isFetchingQuote = { $set: action.isFetchingQuote }
      }
      return update(state, updateObject)
    }

    case 'ACTION_SET_NESTING_RESULT': {
      return update(state, { nestingResult: { $set: action.nestingResult } })
    }

    case 'ACTION_SET_PART_NAME': {
      let partIdx = state.parts.findIndex(p => p.id === action.partId)
      return update(state, { parts: { [partIdx]: { name: { $set: action.name } } } })
    }

    case 'ACTION_UPDATE_ORDER_PART': {
      let orderPartIdx = state.order_parts.findIndex(op => op.id === action.order_part.id)
      return update(state, {
        order_parts: { [orderPartIdx]: { $merge: action.order_part } }
      })
    }

    case 'ACTION_ORDER_LOADED': {
      return update(state, {
        order: { $set: action.order },
      })
    }

    case 'ACTION_ORDER_MERGE': {
      return update(state, {
        order: { $merge: action.order }
      })
    }

    case 'ACTION_ORDER_PARTS_LOADED': {
      return update(state, {
        order_parts: { $set: action.order_parts },
      })
    }

    case 'ACTION_SHOW_LINK_ANIMATOR': {
      return update(state, {
        linkAnimatorMessages: { [action.key]: { $set: action.message } }
      })
    }

    case 'ACTION_HIDE_LINK_ANIMATOR': {
      return update(state, {
        linkAnimatorMessages: { [action.key]: { $set: null } }
      })
    }

    case 'ACTION_TOGGLE_COLOR_CODE_BENDS': {
      return update(state, {
        colorCodeBends: { $set: !state.colorCodeBends }
      })
    }

    case 'ACTION_TOGGLE_SHOW_BEND_ALLOWANCE': {
      return update(state, {
        showBendAllowance: { $set: !state.showBendAllowance }
      })
    }

    case 'ACTION_TOGGLE_NO_BEND_TRANSFORMS': {
      return update(state, {
        disableBendTransforms: { $set: !state.disableBendTransforms }
      })
    }

    case 'ACTION_TOGGLE_SHOW_CONVEX_DECOMPOSITION': {
      return update(state, {
        showConvexGroups: { $set: !state.showConvexGroups }
      })
    }

    case 'ACTION_TOGGLE_SHOW_STACKED': {
      return update(state, {
        showStacked: { $set: !state.showStacked }
      })
    }

    case 'ACTION_SET_PERCENT_BEND_TRANSFORM': {
      return update(state, {
        percentBendTransform: { $set: action.value }
      })
    }

    case 'ACTION_SET_BEND_ADVANCED_EXPANDED': {
      return update(state, {
        bendAdvancedExpanded: { $set: action.value }
      })
    }

    case 'ACTION_TOGGLE_SHOW_TUBE_DEBUG_HINTS': {
      return update(state, {
        showTubeDebugHints: { $set: !state.showTubeDebugHints }
      })
    }

    case 'ACTION_DISMISS_MOBILE_WARNING': {
      return update(state, {
        mobileWarningDismissed: { $set: true }
      })
    }

    case 'ACTION_TOGGLE_USE_V5_BEND_INFO': {
      return update(state, {
        useV5BendInfo: { $set: !state.useV5BendInfo }
      })
    }

    default:
      // A typescript error will appear here if we have not handled all actions
      let _exhaustiveCheck: never = action
      // @ts-ignore
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
