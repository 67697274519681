import { PrimaryButton } from "@oshcut/components"
import { Affiliate, fetchPost } from "@oshcut/oshlib"
import logo from "images/logo.svg"
import { GoArrowLeft, GoArrowRight } from "react-icons/go"
import { Link, useHistory } from "react-router-dom"
import "./AffiliateRegister.scss"
import Marquee from "./Marquee"

import beadBlasting from "images/services/bead-blasting.png"
import bending from "images/services/brake-bending.png"
import laserCutting from "images/services/flat-laser-cutting.png"
import grainFinishing from "images/services/grain-finishing.png"
import deburring from "images/services/metal-deburring.png"
import powderCoating from "images/services/powder-coating.png"
import tapping from "images/services/tapping.png"
import tubeLaserCutting from "images/services/tube-laser-cutting.png"
import tumbling from "images/services/tumbling.png"

import waymoLogo from "images/clients/berkeley.svg"
import tekscanLogo from "images/clients/blackdiamond.svg"
import siemensLogo from "images/clients/boeing.svg"
import mitLogo from "images/clients/bostondynamics.svg"
import jplLogo from "images/clients/climbtech.svg"
import formlabsLogo from "images/clients/dolbyaudio.svg"
import dolbyaudioLogo from "images/clients/formlabs.svg"
import climbtechLogo from "images/clients/jpl.svg"
import bostondynamicsLogo from "images/clients/mit.svg"
import boeingLogo from "images/clients/siemens.svg"
import blackdiamondLogo from "images/clients/tekscan.svg"
import berkeleyLogo from "images/clients/waymo.svg"
import { FormEvent, useRef, useState } from "react"
import { GiCheckMark } from "react-icons/gi"

import sheetMetal2d from "images/part_types/2d-sheet-metal.png"
import sheetMetal3d from "images/part_types/3d-sheet-metal.png"
import tube3d from "images/part_types/3d-tube.png"
import { useSignIn } from "useSignIn"
import { isValidPhoneNumber } from "../CheckoutV2/checkoutReducer"
import { getErrorText } from "../InvoicesView/OpenInvoices"
import { useAlert } from "hooks/useAlert"
import { MdArrowOutward, MdShoppingCart } from "react-icons/md"
import OshcutServiceCard from "./OshcutServiceCard"
import md5 from "md5"

type AffiliateRegisterProps = {
  affiliate: Affiliate
}

const AffiliateRegister = ({ affiliate }: AffiliateRegisterProps) => {

  const { 
    updateCustomerUser, customerUser
  } = useSignIn()
  const history = useHistory()

  const disabled = !!customerUser ? 'disabled' : ''
  
  const servicesScrollContainerRef = useRef<HTMLDivElement>(null)

  function scrollServicesRight() {
    if(servicesScrollContainerRef.current) {
      servicesScrollContainerRef.current.scrollBy({ left: 395, behavior: 'smooth' })
    }
  }

  function scrollServicesLeft() {
    if(servicesScrollContainerRef.current) {
      servicesScrollContainerRef.current.scrollBy({ left: -395, behavior: 'smooth' })
    }
  }

  const formContainerRef = useRef<HTMLFormElement>(null)
  const firstInputRef = useRef<HTMLInputElement>(null)
  function scrollToForm() {
    if(firstInputRef.current && formContainerRef.current) {
      formContainerRef.current.scrollIntoView({ behavior: 'smooth' })
      firstInputRef.current.focus()
    }
  }
  

  const [firstChanged, setFirstChanged] = useState(false)
  const [lastChanged, setLastChanged] = useState(false)
  const [emailChanged, setEmailChanged] = useState(false)
  const [phoneChanged, setPhoneChanged] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [confirmPasswordChanged, setConfirmPasswordChanged] = useState(false)
  
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [updatesOptIn, setUpdatesOptIn] = useState(false)

  const [registerSuccess, setRegisterSuccess] = useState(false)

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const firstError = !first.trim() && firstChanged
  const lastError = !last.trim() && lastChanged
  const emailError = emailChanged ? (!email || !emailRegex.test(email.trim())) : false
  const phoneError = phoneChanged ? !phone || !isValidPhoneNumber(phone.trim()) : false
  const passwordError = (passwordChanged && confirmPasswordChanged) ? (password !== confirmPassword) || !password.trim() || !confirmPassword.trim() : false

  const anyErrors = firstError || lastError || emailError || phoneError || passwordError
  const allFilledOut = !!first && !!last && !!email && !!phone && !!password && !!confirmPassword

  function handleFirstNameBlur() { setFirstChanged(true) }
  function handleLastNameBlur() { setLastChanged(true) }
  function handleEmailBlur() { setEmailChanged(true) }
  function handlePhoneBlur() { setPhoneChanged(true) }
  function handlePasswordBlur() { setPasswordChanged(true) }
  function handleConfirmPasswordBlur() { setConfirmPasswordChanged(true) }

  const [alertContent, showAlert] = useAlert()

  function createAccount(e: FormEvent) {
    e.preventDefault()
    fetchPost("/api/v2/customer/create", {
      email,
      first,
      last,
      phone,
      password: md5(password),
      referred_by_affiliate_id: affiliate.id,
      updates_opt_in: updatesOptIn
    })
      .then(newCustomerUser => {
        updateCustomerUser(newCustomerUser)
        setRegisterSuccess(true)
      })
      .catch(e => {
        showAlert("Failed to Register", getErrorText(e))
      })
  }
  
  return (
    <div className='AffiliateRegister'>
      <div className="header">
        <div className="headerLogoContainer">
          <Link to="/"> <img
            src={logo}
            alt="OSH Cut Metal Fabrication"
          />
          </Link>
        </div>
      </div>

      <div className="hero">

        <div className="left">
          <div className="newCustomerDiscount">
            <Marquee className="newCustomerDiscount">
              <div>
                <p className="smallCaps">New Customer Discount</p>
                <div className="dot" />
              </div>
            </Marquee>
          </div>
          <h1 className="hello">Looks like {breakByCapitals(affiliate.display_name)} sent you our way. <span className="orange">Nice!</span></h1>
          <p>We’re excited to properly thank you both for using our services. At OSH Cut, we have two key obsessions:</p>
          <ol>
            <li>Provide outrageously good experiences for our customers</li>
            <li>Make metal fabrication accessible, fast, affordable, and accurate</li>
          </ol>
          <p>Yes, we realize those are essentially the same thing, and we can’t wait to show you that we really mean it.</p>
        </div>

        <div className="right signUp">
          {registerSuccess ? (<div className="postRegister">
            <img src={logo} alt="OSH Cut Logo" />
            <p className="smallCaps">Registration Successful</p>
            <div className="text">
              <p>Welcome to OSH Cut!</p>
              <p>Keep an eye out for your coupon code; it should arrive in your email shortly. In the meantime, you can visit our <a href="https://oshcut.com/"><MdArrowOutward /> Home Page</a> to learn more about our services, or <Link to="/cart"><MdShoppingCart /> Get Started</Link> placing an order now.</p>
            </div>
            <div className="controls">
              <a href="https://oshcut.com">
                <PrimaryButton><MdArrowOutward /> Home Page</PrimaryButton>
              </a>
              <Link to="/cart">
                <PrimaryButton><MdShoppingCart /> Get Started</PrimaryButton>
              </Link>
            </div>
          </div>) : <>
          <p className="smallCaps limitedTimeOffer">Limited-Time Offer</p>
          <h3>Register today and receive a coupon code for <span className="orange">50% OFF</span> (up to $200) one future purchase with OSH Cut.</h3>
          <form ref={formContainerRef}>
            <div className="inputGrid">
              <label>
                <p>First Name</p>
                <input
                  className={`${firstError ? "error" : undefined} ${disabled}`}
                  type="text"
                  autoComplete="given-name"
                  placeholder="Enter your name"
                  ref={firstInputRef}
                  onBlur={handleFirstNameBlur}
                  onChange={e => setFirst(e.target.value)}
                  disabled={!!customerUser}
                />
              </label>
              <label>
                <p>Last Name</p>
                <input
                  className={lastError ? "error" : undefined}
                  type="text"
                  autoComplete="family-name"
                  placeholder="Enter your name"
                  onBlur={handleLastNameBlur}
                  onChange={e => setLast(e.target.value)}
                  disabled={!!customerUser}
                />
              </label>
              <label>
                <p>E-mail</p>
                <input
                  className={emailError ? "error" : undefined}
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your e-mail"
                  onBlur={handleEmailBlur}
                  onChange={e => setEmail(e.target.value)}
                  disabled={!!customerUser}
                />
              </label>
              <label>
                <p>Phone</p>
                <input
                  className={phoneError ? "error" : undefined}
                  type="text"
                  autoComplete="tel"
                  placeholder="Phone"
                  onBlur={handlePhoneBlur}
                  onChange={e => setPhone(e.target.value)}
                  disabled={!!customerUser}
                />
              </label>
              <label>
                <p>Password</p>
                <input
                  className={passwordError ? "error" : undefined}
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  onBlur={handlePasswordBlur}
                  onChange={e => setPassword(e.target.value)}
                  disabled={!!customerUser}
                />
              </label>
              <label>
                <p>Verify Password</p>
                <input
                  className={passwordError ? "error" : undefined}
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  onBlur={handleConfirmPasswordBlur}
                  onChange={e => setConfirmPassword(e.target.value)}
                  disabled={!!customerUser}
                />
              </label>
              <label className="checkbox">
                <input type="checkbox" onChange={e => setUpdatesOptIn(e.target.checked)} disabled={!!customerUser}/>
                <p>Receive emails about new services, and app updates</p>
              </label>
            </div>

            <PrimaryButton onClick={(e) => createAccount(e)} disabled={!allFilledOut || anyErrors}>Create Account</PrimaryButton>

          </form>
          </>}

        </div>
      </div>

      <div className="customers">
        <p className="smallCaps">We're Trusted By</p>
        <Marquee className="customerLogos">
          <div>
            <img src={waymoLogo} alt="Waymo" />
            <img src={tekscanLogo} alt="Tekscan" />
            <img src={siemensLogo} alt="Siemens" />
            <img src={mitLogo} alt="MIT" />
            <img src={jplLogo} alt="JPL" />
            <img src={formlabsLogo} alt="Formlabs" />
            <img src={dolbyaudioLogo} alt="Dolby Audio" />
            <img src={climbtechLogo} alt="Climb Tech" />
            <img src={bostondynamicsLogo} alt="Boston Dynamics" />
            <img src={boeingLogo} alt="Boeing" />
            <img src={blackdiamondLogo} alt="Black Diamond" />
            <img src={berkeleyLogo} alt="Berkeley" />
          </div>
        </Marquee>
      </div>

      <div className="services">
        <p className="smallCaps">Services</p>
        <h2>Leverage our <span className="orange">state-of-the-art</span><br />equipment and services.</h2>

        <div className="sidewaysScrollContainer serviceCards" ref={servicesScrollContainerRef}>

          <OshcutServiceCard 
            url={'https://oshcut.com/services/laser-metal-cutting'}
            title="Flat Laser Cutting"
            subtitle="Our high-powered fiber lasers precisely cut your metal parts quickly and accurately."
            image={laserCutting}
            list={[
              "State-of-the-art, high power fiber lasers",
              "Supported sheet metal and plate thicknesses range from 0.005\" to 1\"",
              "Stock over 400 grades/alloys of steel, stainless steel, aluminum, copper, brass, and nickel."
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/laser-tube-cutting'}
            title="Tube Laser Cutting"
            subtitle="Our tube lasers specialize in cutting metal tubes or pipes for various applications."
            image={tubeLaserCutting}
            list={[
              'Cut metal tube up to 10" in diameter and square, rectangular, and round stock',
              'Cut up to 3/8" in wall thickness in steel, stainless, and aluminum.',
              'Bevel cuts are supported up to 45 degrees',
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/bending'}
            title="Brake Bending"
            subtitle='On-demand metal bending services in thicknesses up to 1/4".'
            image={bending}
            list={[
              'Bend thicknesses up to 5/16" and total bend lengths up to 108 inches',
              'Any number of bends',
              'Interior "window" bends that require sectionalized tooling',
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/metal-tapping'}
            title="Metal Tapping"
            subtitle="Our equipment makes metal tapping fast and cost-effective for you."
            image={tapping}
            list={[
              'Metal tapping in tap sizes ranging from 6-32 to 1/2-6 (standard)',
              'Metal tapping in tap sizes ranging from M3 x 0.5 to M36 - 4.0 (metric)',
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/finishing'}
            title="Metal Deburring"
            subtitle="Removes burrs and eliminates prep work for welding and painting."
            image={deburring}
            list={[
              "Automatically applied to supported parts and materials during checkout",
              "Can be removed to reduce your part prices"
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/powder-coating'}
            title="Powder Coating"
            subtitle="Powder coating adds a durable and weatherproof finish to your metal parts."
            image={powderCoating}
            list={[
              "For parts up to 50 lbs in weight",
              'For parts up to 60" in length or width',
              '14 standard colors to choose from'
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/finishing'}
            title="Grain Finishing"
            subtitle="The cosmetic technique of using a sanding belt to create a unfiform, directional pattern."
            image={grainFinishing}
            list={[
              "240-grit finishing belt",
              "Produces a brushed surface finish",
              "Available on aluminum and stainless steel"
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/bead-blasting'}
            title="Bead Blasting"
            subtitle="High-pressure abrasion that cleans and preps metal surfaces with a matte finish."
            image={beadBlasting}
            list={[
              "Recycled glass beads at 100 psi",
              "Gives metal a uniform, matte finish",
              "Removes contaminants / preps for paint",
            ]}
          />

          <OshcutServiceCard 
            url={'https://oshcut.com/services/centrifugal-tumbling'}
            title="Tumbling"
            subtitle="A cost-effective technique for polishing batches of smaller parts simultaneously."
            image={tumbling}
            list={[
              "1.4 cu. ft. capacity per run",
              "225 max RPM",
              "Popular for aerospace, medical devices"
            ]}
          />

        </div>

        <div className="servicesControls">
          <p className="smallCaps">Scroll to See More <GoArrowRight /></p>
          <div className="servicesScrollButtons">
            <button onClick={scrollServicesLeft}><GoArrowLeft /></button>
            <button onClick={scrollServicesRight}><GoArrowRight /></button>
          </div>
        </div>

      </div>

      <div className="customParts">
        <h2>Get custom parts like these as fast as <span className="orange">same day</span></h2>

        <div className="sidewaysScrollContainer partTypes">

          <div className="partTypeCard">
            <div className="image">
              <img src={sheetMetal2d} alt="2D Sheet Metal" />
            </div>
            <h3>2D Sheet Metal</h3>
            <p>DXF, SVG, AI</p>
          </div>

          <div className="partTypeCard">
            <div className="image">
              <img src={sheetMetal3d} alt="3D Sheet Metal" />
            </div>
            <h3>3D Sheet Metal</h3>
            <p>STEP, SLDPRT, CATPART, IPT, IGS, PAR, IGES, NX, SolidEdge, JT, Rhino (3DM), Parasolid (x_t), ACIS (SAT, SAB)</p>
          </div>

          <div className="partTypeCard">
            <div className="image">
              <img src={tube3d} alt="3D Tube" />
            </div>
            <h3>3D Tube</h3>
            <p>STEP, SLDPRT, CATPART, IPT, IGS, PAR, IGES, NX, SolidEdge, JT, Rhino (3DM), Parasolid (x_t), ACIS (SAT, SAB)</p>
          </div>

        </div>

        <PrimaryButton onClick={scrollToForm}>Create Account</PrimaryButton>
      </div>

      {customerUser && !registerSuccess && <div className="loggedIn"><div>{customerUser.first_name ? `Hey ${customerUser.first_name}` : 'Hey'}!</div><PrimaryButton onClick={() => history.push('/')}>Go to account</PrimaryButton></div>}
      {alertContent}
    </div>
  )
}

/**
 * Insert a <wbr> tag between each lowercase letter followed by an uppercase letter
 */
function breakByCapitals(name: string) {
  for (let j = 1; j < name.length; j++) {
    if (isLower(name[j - 1]) && isUpper(name[j])) {
      name = `${name.slice(0, j)}<wbr>${name.slice(j)}`
      j += 5 // length of "<wbr>"
    }
  }

  const breaks = name.split("<wbr>")
  const brokenText = breaks.flatMap((part, i) => [part, <wbr key={i} />])
  brokenText.pop() // remove the last <wbr>

  return brokenText
}

const upperRegex = /[A-Z]/
function isUpper(letter: string) {
  return upperRegex.test(letter)
}

const lowerRegex = /[a-z]/
function isLower(letter: string) {
  return lowerRegex.test(letter)
}

export default AffiliateRegister
