import { Dialog } from "@oshcut/components"
import { Customer, CustomerUser, fetchPost, } from "@oshcut/oshlib"
import { useCallback, useContext, useEffect, useReducer } from "react"
import { SignInContext } from "signInContext"
import update, { Spec } from 'immutability-helper'
import SignInForm from "components/SignInForm"
import ForgotPasswordForm from "components/ForgotPasswordForm"
import SignUpForm from "components/SignUpForm"
import CustomerInfoMissing from "components/CustomerInfoMissing"

type SignInCallbackType = (customerUser: CustomerUser) => void

/** Contains the state used by the SignInContext. */
export type SignInStateType = {
  isMoreInfoNeededDialogOpen: boolean
  isSignInDialogOpen: boolean
  isSignUpDialogOpen: boolean
  isForgotPasswordDialogOpen: boolean
  customer: Customer | null,
  customerUser: CustomerUser | null,
  hasTriedSignInOnLoad: boolean,
  onSignInSuccess?: (customerUser: CustomerUser) => void
  onSignInCancelled?: () => void
}

/** Actions to update the SignInContext state. */
export type SignInActionType =
  | { type: 'ACTION_OPEN_MORE_INFO_NEEDED_DIALOG' }
  | { type: 'ACTION_OPEN_SIGNIN_DIALOG' }
  | { type: 'ACTION_OPEN_SIGNUP_DIALOG' }
  | { type: 'ACTION_FORGOT_PASSWORD' }
  | { type: 'ACTION_CLOSE_ALL_DIALOGS' }
  | { type: 'ACTION_SET_CUSTOMER', customer: Customer | null }
  | { type: 'ACTION_SET_CUSTOMER_USER', customerUser: CustomerUser | null }
  | { type: 'ACTION_HAS_TRIED_SIGNIN_ON_LOAD' }
  | { type: 'ACTION_SIGNOUT_USER' }
  | { type: 'ACTION_SET_ON_SIGNIN_CALLBACKS', onSignInSuccess: ((customerUser: CustomerUser) => void) | undefined, onSignInCancelled: (() => void) | undefined }
  | { type: 'ACTION_CLEAR_ON_SIGNIN_CALLBACKS' }

const initialState: SignInStateType = {
  isMoreInfoNeededDialogOpen: false,
  isSignInDialogOpen: false,
  isSignUpDialogOpen: false,
  isForgotPasswordDialogOpen: false,
  customer: null,
  customerUser: null,
  hasTriedSignInOnLoad: false,
}

const reducer = (state: SignInStateType, action: SignInActionType) => {
  // let stateUpdateObject = actionHandlers[action.type](state, action) || {}
  let stateUpdateObject = actionHandlers(state, action)
  let updatedState = update(state, stateUpdateObject)
  return updatedState
}

const actionHandlers = (state: SignInStateType, action: SignInActionType): Spec<SignInStateType> => {
  switch (action.type) {
    case 'ACTION_OPEN_MORE_INFO_NEEDED_DIALOG':
      return { isMoreInfoNeededDialogOpen: { $set: true } }
    case 'ACTION_OPEN_SIGNIN_DIALOG':
      return { isSignInDialogOpen: { $set: true } }
    case 'ACTION_OPEN_SIGNUP_DIALOG':
      return { isSignUpDialogOpen: { $set: true } }
    case 'ACTION_FORGOT_PASSWORD':
      return { isForgotPasswordDialogOpen: { $set: true } }
    case 'ACTION_CLOSE_ALL_DIALOGS':
      return { isSignInDialogOpen: { $set: false }, isSignUpDialogOpen: { $set: false }, isForgotPasswordDialogOpen: { $set: false }, isMoreInfoNeededDialogOpen: { $set: false } }
    case 'ACTION_SET_CUSTOMER':
      return { customer: { $set: action.customer } }
    case 'ACTION_SET_CUSTOMER_USER':
      return { customerUser: { $set: action.customerUser } }
    case 'ACTION_HAS_TRIED_SIGNIN_ON_LOAD':
      return { hasTriedSignInOnLoad: { $set: true } }
    case 'ACTION_SIGNOUT_USER':
      return { customerUser: { $set: null }, customer: { $set: null } }
    case 'ACTION_SET_ON_SIGNIN_CALLBACKS':
      return {
        onSignInSuccess: {
          $set: action.onSignInSuccess
        },
        onSignInCancelled: {
          $set: action.onSignInCancelled
        }

      }
    case 'ACTION_CLEAR_ON_SIGNIN_CALLBACKS':
      return {
        $unset: ['onSignInSuccess', 'onSignInCancelled']
      }

    default:
      return {}
  }
}

/** Provides the SignInContext to the component tree. This should be rendered once near the top level of the app. */
export function SignInProvider({ children }: { children: React.ReactNode }) {

  const [state, dispatch] = useReducer(reducer, initialState)

  /** Is there a valid customer user cookie? See if logging in works. If so, sign in the user. */
  useEffect(() => {
    // Remove the old session storage token if it still exists
    sessionStorage.removeItem('token')
    async function checkCustomerUserCookie() {
      try {
        let response = await fetchPost('/api/v3/customer_user/get_by_token')
        let customerUser = response.result[0].data[0] as CustomerUser
        if (customerUser) {
          delete customerUser.token
          dispatch({ type: 'ACTION_SET_CUSTOMER_USER', customerUser })
        }
      } catch (ex) {
        console.log(ex)
      } finally {
        dispatch({ type: 'ACTION_HAS_TRIED_SIGNIN_ON_LOAD' })
      }
    }
    checkCustomerUserCookie()
  }, [])

  /** When the customerUser changes, load the customer. */
  useEffect(() => {
    if (!state.customerUser) {
      dispatch({ type: 'ACTION_SET_CUSTOMER', customer: null })
      return
    }
    async function loadCustomer() {
      try {
        let customer = await fetchPost('/api/v2/customer/get')
        if (customer) {
          dispatch({ type: 'ACTION_SET_CUSTOMER', customer })
        }
      } catch (e) { }
    }
    loadCustomer()
  }, [state.customerUser])

  return (
    <SignInContext.Provider value={{ state, dispatch }}>
      {children}
    </SignInContext.Provider>
  )
}

/**
 * Renders the signIn, signUp, or forgot password forms and related alerts. You only need to render this once near the
 * top level of the app.
 */
export function AllSignInForms() {

  const { state, dispatch } = useContext(SignInContext)

  if (!state) throw new Error('AllSignInForms must be used within a SignInProvider')

  function handleClose() {
    dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    state.onSignInCancelled?.()
  }

  if (state.isSignInDialogOpen) {
    return (
      <Dialog open title="Sign In" onClose={handleClose}>
        <SignInForm />
      </Dialog>
    )
  } else if (state.isSignUpDialogOpen) {
    return (
      <Dialog open title="Create Account" onClose={handleClose}>
        <SignUpForm />
      </Dialog >
    )
  } else if (state.isForgotPasswordDialogOpen) {
    return (
      <Dialog open title="Forgot Password" onClose={handleClose}>
        <ForgotPasswordForm />
      </Dialog>
    )
  } else if (state.isMoreInfoNeededDialogOpen) {
    return (
      <Dialog open title="Finish Your Account" onClose={handleClose}>
        <CustomerInfoMissing />
      </Dialog>
    )
  }

  return null
}

/**
 * Custom hook that can be used to display the signIn dialog, retrieve the current user, and so on.
 */
export function useSignIn() {

  const { state, dispatch } = useContext(SignInContext)

  useEffect((() => {
    if (state.hasTriedSignInOnLoad) {
      console.log('useSignIn: hasTriedSignInOnLoad is now true')
    }
  }), [state.hasTriedSignInOnLoad])

  const showSignIn = useCallback((onSignIn?: SignInCallbackType, onCancel?: () => void) => {
    if (state.customerUser || !state.hasTriedSignInOnLoad) {
      // Already signed in, or still trying initial sign in, so do nothing
      if (state.customerUser) {
        onSignIn?.(state.customerUser)
      } else {
        onCancel?.()
      }
    } else {
      // Cancel previous callback, if it exists
      state.onSignInCancelled?.()
      dispatch({ type: 'ACTION_SET_ON_SIGNIN_CALLBACKS', onSignInSuccess: onSignIn, onSignInCancelled: onCancel })
      dispatch({ type: 'ACTION_OPEN_SIGNIN_DIALOG' })
    }
  }, [state.customerUser, state.isSignInDialogOpen, state.hasTriedSignInOnLoad])

  const showSignInPromise = useCallback(() => {
    return new Promise<CustomerUser>((resolve, reject) => {
      showSignIn((customerUser) => {
        resolve(customerUser)
      }, reject)
    })
  }, [showSignIn])

  const showSignUp = useCallback(() => {
    if (state.customerUser) {
      // Already signed in, so do nothing
    } else {
      dispatch({ type: 'ACTION_OPEN_SIGNUP_DIALOG' })
    }
  }, [state.customerUser])

  const signOut = useCallback(() => {
    fetchPost('/api/v1/customer/logout')
    dispatch({ type: 'ACTION_SIGNOUT_USER' })
  }, [])

  const updateCustomerUser = useCallback((customerUser: CustomerUser) => {
    dispatch({ type: 'ACTION_SET_CUSTOMER_USER', customerUser })
  }, [])

  const updateCustomer = useCallback((customer: Customer) => {
    if (customer) {
      dispatch({ type: 'ACTION_SET_CUSTOMER', customer })
    }
  }, [])

  return {
    customer: state.customer,
    customerUser: state.customerUser,
    isCompany: !!state.customer?.is_company,
    isCompanyAdmin: !!state.customer?.is_company && !!state.customerUser?.role.includes('admin'),
    areAnySignInDialogsOpen: state.isSignInDialogOpen || state.isSignUpDialogOpen || state.isForgotPasswordDialogOpen,
    hasTriedSignInOnLoad: state.hasTriedSignInOnLoad,
    /**
     * Shows the sign in dialog. Returns a Promise that resolves when the user successfully logs in or creates an account,
     * and rejects if the user closes the dialog without logging in.
     */
    showSignIn: showSignInPromise,
    showSignUp,
    signOut,
    updateCustomer,
    updateCustomerUser,
  }
}
