import './css/CustomerInfoMissing.scss'
import { FormEvent, useContext, useState } from 'react'
import { fetchPost, filterResponse} from '@oshcut/oshlib'
import { LinkButton, PrimaryButton, SecondaryButton } from '@oshcut/components'
import { SignInContext } from 'signInContext'

const CustomerInfoMissing = () => {

  const { state, dispatch } = useContext(SignInContext)
  const customerUser = state.customerUser

  const [submitted, setSubmitted] = useState(false)

  const [firstName, setFirstName] = useState(customerUser?.first_name || "")
  const [lastName, setLastName] = useState(customerUser?.last_name || "")
  const [phone, setPhone] = useState(customerUser?.phone || "")

  const [validateError, setValidateError] = useState("")

  const [submitError, setSubmitError] = useState("")

  async function handleAccept(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    setSubmitError("")

    // Insures that there isn't just whitespace entered
    if (!(/^(?=.*\S).+$/.test(firstName))) {
      setSubmitError('Please enter first name.')
      return
    }

    // Insures that there isn't just whitespace entered
    if (!(/^(?=.*\S).+$/.test(lastName))) {
      setSubmitError('Please enter last name.')
      return 
    }

    // checks for a valid phone number 
    if (!(/^(\+?\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/.test(phone))) {
      setSubmitError('Invalid phone number.')
      return 
    }

    if (!customerUser) {
      setValidateError('No customer user found.')
      return
    }

    try {
      const result = await fetchPost('/api/v3/customer_user/update', {
        id: customerUser?.id ,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
      })

      const updatedCustomerUser = filterResponse(result, 'customer_user', 'update')[0]
      dispatch({ type: 'ACTION_SET_CUSTOMER_USER', customerUser: updatedCustomerUser })
      setSubmitted(true)
    } catch(e: any) {
      setSubmitError(e.serverMessage)
    }
  }

  function handleClose() {
    dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
  }

  return (
    <div className='activateAccount'>

      {submitted ? (<>

        <div style={{ textAlign: 'center' }}>
          <p>Thank you!</p>
          <PrimaryButton style={{ margin: '0 auto' }} onClick={handleClose}>Continue to OSH Cut</PrimaryButton>
        </div>

      </>) : (

      (customerUser ) ? (
        <>
          <p>Please finish setting up your account:</p>

          <form className='form activateAccountForm' onSubmit={handleAccept}>

          <label>
              <span>Email</span>
              <b>{customerUser.email}</b>
            </label>

            <label>
              <span>First Name</span>
              <input autoComplete="given-name" type="text" placeholder='First Name' value={firstName} onChange={e => setFirstName(e.target.value)} />
            </label>

            <label>
              <span>Last Name</span>
              <input autoComplete="family-name" type="text" placeholder='Last Name' value={lastName} onChange={e => setLastName(e.target.value)} />
            </label>

            <label>
              <span>Phone</span>
              <input autoComplete="tel" type="tel" placeholder='Phone' value={phone} onChange={e => setPhone(e.target.value)} />
            </label>

            {submitError && <p className='error'>{submitError}</p>}

            <PrimaryButton type="submit" disabled={!firstName || !lastName || !phone }>Update Account</PrimaryButton>
            <LinkButton onClick={handleClose}>I'll do this later</LinkButton>
          </form>
        </>) 
        : validateError ? (
          <p>{validateError}</p>
        ) : <p>Loading...</p>
      )}
    </div>
  )

}

export default CustomerInfoMissing