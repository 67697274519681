import { useDropdown } from '@oshcut/components'
import { fetchPost, LibraryPart, OrderPart } from '@oshcut/oshlib'
import React, { useRef, useState } from 'react'
import { CartPart } from 'types'
import { getLibraryPartName, ItemToAdd } from '../util'
import { AddToPartLibraryDialog } from './PartLibrary/AddToPartLibraryDialog'
import { MdAddShoppingCart, MdBookmarkAdd } from 'react-icons/md'
import { FaEllipsis } from 'react-icons/fa6'
import './css/PartEllipsisMenuActions.scss'

type OrderPartsTableRowProps = {
  part: CartPart
  onAddToCart: (itemsToAdd: ItemToAdd[]) => void
  onAddToPartLibrary: (libraryPart: LibraryPart, orderPart: OrderPart) => void
}

export function PartEllipsisMenuActions({ part, onAddToCart, onAddToPartLibrary }: OrderPartsTableRowProps) {

  const isPart = part.id?.indexOf('P-') === 0

  const refEllipsisMenu = useRef<HTMLDivElement>(null)

  const { Dropdown, openDropdown, closeDropdown } = useDropdown(refEllipsisMenu, { horizontalAlign: 'flush-end' })

  const [showAddToPartLibraryDialog, setShowAddToPartLibraryDialog] = useState(false)

  async function handlePartAddedToLibrary(libraryPart: LibraryPart) {
    // Combine the part number and revision to get the new part name
    const updatedName = getLibraryPartName(libraryPart)

    // Save the new item ID in the database
    let updatedOrderPart = await fetchPost('/api/v2/order_part/update', {
      guid: part.orderPart.guid,
      name: updatedName,
      item_id: libraryPart.item.id,
    })

    onAddToPartLibrary(libraryPart, updatedOrderPart)
  }


  return <>
    {isPart &&
      <div className='PartEllipsisMenuButton'>
        <div className='ellipsisMenu' ref={refEllipsisMenu} onClick={openDropdown}><FaEllipsis size={20} /></div>
      </div>
    }
    <Dropdown className='PartEllipsisMenuDropdown'>
      <div className='dropdownItem' onClick={() => { closeDropdown(); onAddToCart([{ orderPart: part.orderPart, libraryPart: part.libraryPart }]) }}><MdAddShoppingCart /> Add to Cart</div>
      {!part.isLibraryPart && <div className='dropdownItem' onClick={() => { closeDropdown(); setShowAddToPartLibraryDialog(true) }}><MdBookmarkAdd /> Save to Part Library...</div>}
    </Dropdown>

    {showAddToPartLibraryDialog &&
      <AddToPartLibraryDialog
        mode='new'
        onClose={() => setShowAddToPartLibraryDialog(false)}
        part={part}
        onLibraryPartSaved={handlePartAddedToLibrary}
        requireErrorCheck={false}
      />
    }

  </>
}