import { DispatchFunction } from "reducer"
import { ParsedOrder, PartType, StateType } from "types"
import { useNotify } from "./useNotify"
import { useSignIn } from "useSignIn"
import { useHistory } from "react-router-dom"
import { getLibraryPartName, ItemToAdd } from "../util"
import plural from "plural"
import { useLoadingOverlay } from "./useLoadingOverlay"
import { fetchPost, Order, OrderForCustomer, Part } from "@oshcut/oshlib"

/**
 * Adds the provided orderParts list to the current order, (defined in `state`),
 * while displaying loading overlays and error messages as necessary. Redirects
 * to the cart page if successful.
 * @param orderParts 
 * @param state 
 * @param dispatch 
 * @param notify 
 * @param showLoadingOverlay 
 * @param hideLoadingOverlay 
 * @param redirect 
 * @param setError 
 * @returns 
 */
export function useAddPartsToCurrentOrder({
  state,
  dispatch,
  onError,
  onOrderCreated,
}: {
  state: StateType,
  dispatch: DispatchFunction,
  onError: (msg: string) => void,
    onOrderCreated?: (order: ParsedOrder<OrderForCustomer>) => void,
}) {

  const history = useHistory()
  const { customer } = useSignIn()
  const { notify } = useNotify()

  const [overlayContent, showLoadingOverlay, hideLoadingOverlay] = useLoadingOverlay();


  async function addPartsToCurrentOrder(itemsToAdd: ItemToAdd[]) {

    // TODO: This function should probably be a custom hook

    showLoadingOverlay(`Adding ${itemsToAdd.length} ${plural('part', itemsToAdd.length)} to your cart`)

    let nSuccessfullyCreated = 0
    let nSuccessfullyUpdated = 0

    let toOrderId = state.order?.guid

    if (!toOrderId) {
      // Create a new order
      let payload: Partial<Order> = {}
      if (customer?.id) {
        payload.customer_id = customer.id
      }
      let response
      try {
        response = await fetchPost('/api/v2/order/create', payload)
        if (!response.guid) {
          throw new Error('No guid present in response')
        }
      } catch (ex) {
        onError('There was a problem creating your order. Please contact support for assistance.')
        hideLoadingOverlay()
        return
      }
      toOrderId = response.guid
      dispatch({ type: 'ACTION_ORDER_LOADED', order: response })
      onOrderCreated?.(response)
      sessionStorage.setItem('orderId', response.guid)
    }

    let displayOrder = Math.max(-1, ...state.parts.map(p => p.displayOrder)) + 1

    // Duplicate each part and create an order_part


    for (let thing of itemsToAdd) {

      const orderPart = thing.orderPart
      const libraryPart = thing.libraryPart
      const partId = orderPart?.part_id?.replace('N-', 'P-') as Part['id'] ?? libraryPart?.item.part_id

      const isLibraryPart = !!libraryPart?.item.customer_part_number
      const qty = thing.qty ?? orderPart?.qty ?? 1
      const partName = (isLibraryPart && libraryPart && getLibraryPartName(libraryPart)) || orderPart?.name

      try {

        if (!partId) {
          throw new Error('No part ID found')
        }
        if (!partName) {
          throw new Error('No part name found')
        }

        let newPartId: Part['id']

        // Library parts should be added without duplicating
        if (isLibraryPart) {
          newPartId = partId
        } else {

          let responseDuplicate = await fetchPost('/api/v2/arcify/duplicate', {
            partId,
            version: 'latest',
          })

          newPartId = responseDuplicate.newPartId
        }

        let newPart: PartType = {
          id: newPartId,
          name: partName,
          arcified: undefined,
          status: 'PART_STATUS_NOT_LOADED',
          dfmStatus: 'DFM_STATUS_INVALID',
          quantity: qty,
          quantityValid: true,
          displayOrder,
          isLibraryPart,
          isReadonly: isLibraryPart && !!libraryPart.mostRecentOrder
        }

        // Create the order part, or increment the quantity if it already exists
        let order_part_response = await fetchPost('/api/v2/order_part/add_to_order', {
          order_guid: toOrderId,
          item_id: isLibraryPart ? libraryPart.item.id : undefined,
          part_id: newPartId,
          qty: qty,
          name: partName,
          display_order: displayOrder,
        })

        if (order_part_response.action === 'create') {
          // Add new part and orderPart to our state (sometimes the part could already exist, if it was previously deleted)
          dispatch({ type: 'ACTION_CREATE_PART', part: newPart })
          dispatch({ type: 'ACTION_CREATE_ORDER_PART', order_part: order_part_response.order_part })

          if (isLibraryPart && libraryPart) {
            dispatch({ type: 'ACTION_LIBRARY_PART_LOADED', libraryPart })
          }

          dispatch({ type: 'ACTION_CREATE_ORDER_PART', order_part: order_part_response.order_part })

          // When the user navigates to the cart, the useOrderLoader hook will trigger the arcify processing of the new
          // parts.

          // New part, so bump the display order
          displayOrder++
          nSuccessfullyCreated++

        } else {
          // Increment the quantity of an existing part
          dispatch({ type: 'ACTION_SET_QUANTITY', partId: newPartId, quantity: order_part_response.order_part.qty })
          nSuccessfullyUpdated++
        }

        // Increment the number of successful parts added or updated

      } catch (ex) {
        console.error(ex)
      }
    }

    dispatch({ type: 'ACTION_INVALIDATE_QUOTE' })
    hideLoadingOverlay()

    // Finally, show the parts added to the cart (after showing any error messages)
    if ((nSuccessfullyCreated + nSuccessfullyUpdated) !== itemsToAdd.length) {
      let nFailed = itemsToAdd.length - (nSuccessfullyCreated + nSuccessfullyUpdated)
      let msg = `Could not add ${nFailed} ${plural('part', nFailed)} to your cart.`
      onError(msg)
    } else {
      let message
      if (nSuccessfullyCreated > 0 && nSuccessfullyUpdated === 0) {
        message = `Added ${nSuccessfullyCreated} ${plural('part', nSuccessfullyCreated)} to your cart.`
      } else if (nSuccessfullyCreated === 0 && nSuccessfullyUpdated > 0) {
        message = `Increased the quantity of ${nSuccessfullyUpdated} ${plural('part', nSuccessfullyUpdated)} in your cart.`
      } else if (nSuccessfullyCreated > 0 && nSuccessfullyUpdated > 0) {
        message = `Added ${nSuccessfullyCreated} ${plural('part', nSuccessfullyCreated)} and increased the quantity of ${nSuccessfullyUpdated} ${plural('part', nSuccessfullyUpdated)} in your cart.`
      } else {
        message = `No parts were added or updated.`
      }
      notify({
        message,
        timeout: 10000,
        linkMessage: 'View Cart',
        linkCallback: () => {
          history.push(`/cart/${encodeURIComponent(toOrderId!)}`)
        }
      })
    }

  }

  return {
    addPartsToCurrentOrder,
    overlayContent
  }
}